@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

$color-green01: #06CC77;
$color-green02: #00A338;

$color-blue01: #51A1F2;
$color-blue02: #1A66E5;

$color-grey01: #5F5F5F;


//font-family
$noto-sans: "Noto Sans JP",sans-serif;
$noto-serif: 'Noto Serif JP', serif;

//font-weight
$regular: 300;
$medium: 400;
$semibold: 500;
$bold: 600;
$black: 900;

$opacity: 0.7;


/*
非表示
-------------------------------------*/
/*ヘッダーメニュー*/

#HeaderMenu .headerMenuCont .headerMenuLink .linkBox02{
	//display: none;
}

/*受験生応援サイト*/
#PageExaminee.pageIndex #Header .linkBox02{
	//display: none;
}


/*
トップページ
-------------------------------------*/
#Page.pageIndex {
	#Header{
		position: fixed;
		height: 225px;
		background-color: transparent;
		#ContBoxHeader{
			display: block;
			h1{
				width: 966px;
				height: 225px;
				background: linear-gradient(166.9deg, #fff 0%, #fff 50%, transparent 50%);
				background-size: 965px auto;
				background-repeat: no-repeat;
				text-indent: -9999px;
				position: relative;
				padding-top: 18px;
				padding-left: 25px;
				&::after{
					content: "";
					display: block;
					background-image: url("../img/contents/top/mainimg_parts01.png");
					background-size: 966px auto;
					background-repeat: no-repeat;
					width: 966px;
					height: 225px;
					position: absolute;
					left: 0;
					bottom: 0;
				}
				a{
					background: url("../img/header/logo_top.png") no-repeat left center;
					background-size: 312px auto;
					width: 312px;
					height: 68px;
				}
			}
		}
	}
	&.fixed #Header{
		height: 70px;
		background-color: #fff;
		#ContBoxHeader{
			h1{
				background: none;
				height: auto;
				width: 100%;
				padding-top: 8px;
				padding-left: 24px;
				top: 0;
				&::after{
					display: none;
				}
				a{
					background-image: url(../img/header/logo_scroll_pc.png);
					background-size: 335px auto;
					width: 335px;
					height: 51px;
				}
			}
		}
	}
	#MainImg{
		#MainImgInner{
			width: 100%;
			position: relative;
			h2 {
				position: absolute;
				font-family: "Noto Serif JP", "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;
				font-weight: 600;
				letter-spacing: 0.1em;
				z-index: 40;
				text-indent: -9999px;
				background-repeat: no-repeat;
				background-position: center center;
			span { color: #007C86; }
			}
		}
		.slide {
			width: 100%;
	  		height: 100%;
			#Slide {
				position: relative;
				width: 100%;
				height: 100%;
				overflow-x: hidden;
				.slick-list{
					li {
						width: 100%;
						height: 650px;
						overflow: hidden;
						text-align: center;
						figure{
							width: 100%;
							height: 100%;
							background-repeat: no-repeat;
							background-position: center center;
							background-size: cover;
						}
					}
				}
				.slick-dots{
					position: absolute;
					display: flex!important;
					flex-wrap: wrap;
					li{
						text-indent: -9999px;
						margin-right: 6px;
						&:last-of-type{
							margin-right: 0;
						}
						&.slick-active{
						}
					}
				}
			}
		}
	}
	#ImportantBox{
		  background-color: #222222;
		  .importantPostWrap{
			  h3{
				  background-color: #CECECE;
				  color: #1A1A1A;
				  font-weight: $medium;
			  } 
			  ul{
				  li{
					  .time{
						  color: #C4C4C4;
					  }
					  .postItem{
						  a{
							  color: #fff;
							  text-decoration: none;
						  }
					  }
				  }
			  }
		  }
	  }
	#Main{
		.contBox {
		}
		#ContBox01{
			.contSubBox01{
			}
			.contSubBox02{
				.wrap{
					a{
						text-decoration: none;
					}
				}
			  .txtBox{
				  background-color: #51A1F2;
				  background-repeat: no-repeat;
				  background-position: center;
				  background-size: cover;
				  color: #fff;
				  font-weight: $semibold;
				  p{

				  }
				  h3{

				  }
				  .btnTypeBasic{

				  }
			  }
			  .imgBox{
				  background-repeat: no-repeat;
				  background-position: center;
				  background-size: cover;
				  
			  }
			}
		}
		#ContBox02{
			.contSubBox{
				background-repeat: no-repeat;
				h4{
					font-family: $noto-serif;
					font-weight: $bold;
					strong{
						font-family: $noto-sans;
					}
				}
				.slide{
					position: relative;
					ul{
						position: relative;
					}
					.slick-arrow{
						background-color: #fff;
						background-position: center center;
						background-repeat: no-repeat;
						text-indent: -9999px;
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						z-index: 60;
						box-shadow: 0 0 10px rgba(0, 0, 0, .1);
						&.slick-prev{
							left: 0;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
						}
						&.slick-next{
							right: 0;
							box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
						}
					}
				}
			}
			.contSubBox01{
				h4{
					strong{
						color: #31C26E;
					}
				}
			}
			.contSubBox02{
				h4{
					strong{
						color: $color-blue01;
					}
				}
			}

		}
		#ContBox03 {
			background: #CBEEF2;
			background: -moz-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
			background: -webkit-gradient(linear, left top, left bottom, from(#E4F8F1), to(#CBEEF2));
			background: -webkit-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
			background: -o-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
			background: linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
			.btnWrap{
				.btnTypeMore{
					background-image: none;
				}
			}
		}
		#ContBox04 {
			.subBoxWrap {
				.subBox01 {
				  h4{
				  	font-weight: $medium;
				  }
				}
				.subBox02 {
					p{
						font-weight: $medium;
					}
					dl{
						dt{
							font-weight: $bold;
							color: #fff;
						}
						dd{
						}
					}
				}
			}
		}
	}
}



@media print, screen and (min-width: 768px) {
	#Page.pageIndex {
		#MainImg {
			height: 650px;
			#MainImgInner {
				height: auto;
				max-width: none;
				h2 {
					font-size: 80px;
					line-height: 1.44;
					background-image: url(../img/contents/top/main_txt_pc.png);
					background-size: 573px auto;
					width: 573px;
					height: 311px;
					top: 305px;
					left: 120px;
				}
			}
			.slide {
				#Slide {
					.slick-list{
						li {
							&.slider01 figure { background-image: url("../img/contents/top/main_visu_a_pc.jpg"); }
							&.slider02 figure { background-image: url("../img/contents/top/main_visu_b_pc.jpg"); }
							&.slider03 figure { background-image: url("../img/contents/top/main_visu_c_pc.jpg"); }
							&.slider01 figure img { display: none; }
							&.slider02 figure img { display: none; }
							&.slider03 figure img { display: none; }
						}
					}
					.slick-dots {
						right: 50px;
						bottom: 18px;
							li{
							width: 69px;
							height: 30px;
							position: relative;
							background-color: transparent;
							&:hover{
								cursor: pointer;
								opacity: $opacity;
							}
							&.slick-active{
								background-color: transparent;
								button{
									background-color: #87EDA6;
								}
							}
							button{
								width: 69px;
								height: 4px;
								border-radius: 2px;
								background-color: #FFFFFF;
								text-indent: -9999px;
								position: absolute;
							    top: 50%;
							    right: 0;
							    transform: translateY(-50%);
							    z-index: 70;
							}
						}
					}
				}
			}
		}
		#ImportantBox{
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			.importantPostWrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 43px 0;
				h3{
					width: 152px;
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 100px;
				}
				ul{
					width: calc(100% - 188px);
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					li{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						margin-bottom: 5px;
						&:last-of-type{
							margin-bottom: 0;
						}
						.time{
						  font-size: 13px;
						  width: 120px;
						}
						.postItem{
						  width: calc(100% - 120px);
						  margin-bottom: 0;
						  line-height: 1.5;
						  position: relative;
						  top: -4px;
						  a{
							  font-size: 14px;
							  &:hover{
							  	text-decoration: underline;
							  }
						  }
						}
					}
				}
			}
		}
	    #Main{
			.contBox {
				.innerBasic {
				}
			}
			#ContBox01{
				padding:  125px 0 0;
				.titleTypeBasic{
					margin-bottom: 50px;
				}
				.contSubBox01{
				}
				.contSubBox02{
					padding: 90px 35px 0;
					a{
						display: flex;
						flex-wrap: wrap;
						flex-direction: row-reverse;
						text-decoration: none;
						&:hover{
							.imgBox{
								transform: scale(1.12);
							}
							.btnTypeBasic {
								span{
					                &::before{
					                    left: calc(100% - 35px);
					                    opacity: 0.2;
					                }
					            }
							}
						}
					}
					.imgBoxWrap{
						width: 53%;
						overflow: hidden;
						.imgBox{
							background-image: url("../img/contents/top/img01_pc.jpg");
							width: 100%;
							height: 100%;
							transition: transform 1s ease;
						}
					}
					.txtBox{
						background-image: url("../img/contents/top/bg01_pc.png");
						width: 47%;
						padding: 37px 70px 40px;
						p{
							font-size: 25px;
							margin-bottom: 23px;
						}
						h3{
							font-size: 40px;
							line-height: 1.2;
							margin-bottom: 40px;
						}
						.btnWrap{
						  width: 240px;
						}
					}
				}
			}
			#ContBox02{
				padding:  85px 0 70px;
				.titleTypeBasic{
					margin-bottom: 40px;
				}
				.contSubBox{
					background-size: 630px auto;
					position: relative;
					overflow: hidden;
					padding-bottom: 28%;
					.innerBasic{
						max-width: 1000px;
						margin: 0 auto;
					}
					.subBox{
						width: 50%;
						padding: 55px 0 0;
						h4{
							font-size: 35px;
							margin-bottom: 20px;
							strong{
								font-size: 45px;
							}
						}
						p{
							font-size: 18px;
							margin-bottom: 40px;
						}
						.btnWrap{
							width: 340px;
						}
					}
					.slide {
						width: 78%;
				  		height: auto;
				  		position: absolute;
				  		top: 370px;
				  		.slick-arrow{
							width: 60px;
							height: 60px;
							background-size: 30px auto;
							&:hover{
								opacity: $opacity;
							}
						}
						ul {
							position: relative;
							width: 100%;
							height: 100%;
							li {
								margin-right: 20px;
								width: 100%;
								overflow: hidden;
								text-align: center;
								a:hover{
									overflow: hidden;
									figure{
										transform: scale(1.12);
									}
								}
								figure{
									transition: transform 1s ease;
									width: 100%;
									height: 100%;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: cover;
									padding-top: 70%;
									position: relative;
									figcaption{
										position: absolute;
										left:20px;
										bottom: 10px;
										color: #fff;
										font-size: 16px;
										font-weight: $medium;
									}
								}
							}
						}
						.bgBoxWrap{
							position: absolute;
							//right: calc(calc(calc(100% * 1.28) / 2) + 29.5%);
							top: -370px;
							width: 100%;
							height: 700px;
						    z-index: -1;
						    //max-width: calc(100% * 1.28);
						    max-width: 56%;
							.bgBox{
								.bgBoxIn{
									width: 100%;
									height: 700px;
									background-position: center center;
								    //background-image: url(../img/contents/top/bg02_pc.jpg);
								    background-size: cover;
								}
							}
						}
					}
				}
				.contSubBox01{
					//background-image: url(../img/contents/top/bg02_pc.jpg);
					//background-position: left top;
					margin-bottom: 30px;
					.subBox{
						margin: 0 0 0 auto;
						h4{

						}
					}
					.slide {
						right: -240px;
						.slick-arrow{
							&.slick-prev{
								background-image: url(../img/contents/arrow_01_g_p.png);
								left: -30px;
							}
							&.slick-next{
								background-image: url(../img/contents/arrow_01_g_n.png);
								right: 32%;
							}
						}
						ul{
							li{
								figure{
								}
								&.slider01 figure { background-image: url("../img/contents/top/slide01_01.jpg"); }
								&.slider02 figure { background-image: url("../img/contents/top/slide01_02.jpg"); }
								&.slider03 figure { background-image: url("../img/contents/top/slide01_03.jpg"); }
								&.slider04 figure { background-image: url("../img/contents/top/slide01_04.jpg"); }
								&.slider05 figure { background-image: url("../img/contents/top/slide01_05.jpg"); }
							}
						}
						.bgBoxWrap{
							right: calc(calc(calc(100% * 1.28) / 2) + 29.5%);
							.bgBox{
								.bgBoxIn{
								    background-image: url(../img/contents/top/bg02_pc.jpg);
								}
							}
						}
					}
				}
				.contSubBox02{
					//background-image: url(../img/contents/top/bg03_pc.jpg);
					//background-position: right top;
					.slide {
						left: -240px;
						.slick-arrow{
							&.slick-prev{
								background-image: url(../img/contents/arrow_01_b_p.png);
								left: 30%;
							}
							&.slick-next{
								background-image: url(../img/contents/arrow_01_b_n.png);
								right: -17px;
							}
						}
						ul{
							li{
								figure{
								}
								&.slider01 figure { background-image: url("../img/contents/top/slide02_01.jpg"); }
								&.slider02 figure { background-image: url("../img/contents/top/slide02_02.jpg"); }
								&.slider03 figure { background-image: url("../img/contents/top/slide02_03.jpg"); }
								&.slider04 figure { background-image: url("../img/contents/top/slide02_04.jpg"); }
								&.slider05 figure { background-image: url("../img/contents/top/slide02_05.jpg"); }
							}
						}
						.bgBoxWrap{
							left: calc(calc(calc(100% * 1.28) / 2) + 29.5%);
							.bgBox{
								.bgBoxIn{
								    background-image: url(../img/contents/top/bg03_pc.jpg);
								}
							}
						}
					}
				}
			}
			#ContBox03{
				.wrap{
					padding: 50px 0 60px;
					background-image: url(../img/contents/top/bg04_pc.png), url(../img/contents/top/bg05_pc.png);
					background-repeat: no-repeat, no-repeat;
					background-size: 821px auto, 565px auto;
					background-position: left top, right bottom;
				}
				.innerBasic {
					max-width: 1000px;
					margin: 0 auto;
				}
				.titleTypeBasic{
					margin-bottom: 60px;
				}
				.postType02.disp_sp{
					display: none;
				}
				.btnWrap{
					width: 340px;
					margin: 40px auto 0;
					.btnTypeMore{
					}
				}
			}
			#ContBox04{
				padding:  75px 0 70px;
				.titleTypeBasic{
					margin-bottom: 100px;
				}
				.innerBasic {
					max-width: 1090px;
					margin: 0 auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				.map{
					width: 55.5%;
					height: 460px;
					margin-right: 70px;
					iframe{
						width: 100%;
					}
				}
				.subBoxWrap{
					width: calc(45.5% - 82px);
					.subBox01{
						margin-bottom: 30px;
						h4{
							font-size: 22px;
							margin-bottom: 6px;
						}
					}
					.subBox02{
						margin-bottom: 20px;
						p{
							margin-bottom: 7px;
						}
						dl{
							dt{
								background-color: #5E5E5E;
								font-size: 14px;
								padding: 5px 15px;
							}
							dd{
								padding: 10px 15px 15px;
							}
						}
					}
					.btnWrap{
						width: 226px;
					}
				}
			}
		}
	}
}
@media print, screen and (min-width: 1440px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox{
		padding-bottom: 25%;
	}
}
@media print, screen and (min-width: 1600px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox{
		padding-bottom: 25%;
	}
}
@media print, screen and (min-width: 1800px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox{
		padding-bottom: 23%;
	}
}
@media print, screen and (min-width: 2000px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox{
		padding-bottom: 21%;
	}
}

@media print, screen and (max-width: 1440px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox .slide .bgBoxWrap{
		max-width: 65%;
	}
}
@media print, screen and (max-width: 1020px) {
	#Page.pageIndex #Main #ContBox02 .contSubBox .slide .bgBoxWrap{
		max-width: 75%;
	}
}

@media print, screen and (max-width: 999px) {
	#Page.pageIndex #Main #ContBox01 .contSubBox02 .txtBox{
		padding-left: 30px;
		padding-right: 30px;
	}
}
@media print, screen and (max-width: 767px) {
	#Page.pageIndex {
		#Header{
			height: auto;
			#ContBoxHeader{
				background-color: transparent;
				h1{
					width: 100%;
					height: 164px;
					text-indent: -9999px;
					padding-top: 0;
					padding-left: 0;
					background-image: url("../img/contents/top/mainimg_parts01_sp.png");
					background-size: 761px auto;
					background-repeat: no-repeat;
					background-position: left top;
					position: relative;
					&::after{
						display: none;
						/*content: "";
						display: block;
						background-image: url("../img/contents/top/mainimg_parts01_sp.png");
						background-size: 392px auto;
						background-repeat: no-repeat;
						width: 392px;
						height: 160px;
						position: absolute;
						left: 0;
						bottom: -8px;*/
					}
					a{
						background-image: url(../img/header/logo_sp.png);
		                background-size: auto 47px;
		                width: 141px;
		                height: 47px;
						
						margin-top: 0;//
						margin-left: 0;//
						
						position: absolute;
						top: 10px;
						left: 15px;
					}
				}
			}
		}

		&.fixed #Header{
			background-color: #fff;
		    //height: 70px;
		    #ContBoxHeader{
		        h1{
		            background: none;
		            height: auto;
		            width: 100%;
		            padding-top: 8px;
		            padding-left: 24px;
		            top: 0;
		            &::after{
		                display: none;
		            }
		            a{
		                background-image: url(../img/header/logo_sp.png);
		                background-size: auto 47px;
		                width: 141px;
		                height: 47px;
		            }
		        }
		    }
		}

		&.fixed #MainImg{
			padding-top: 69px;
		}
		#MainImg{
			padding-top: 0;
			height: 670px;
			#MainImgInner{
				height: auto;
				//background-image: url(../img/contents/mainimage_sp.jpg);
				padding: 0;
				h2 {
					background-image: url(../img/contents/top/main_txt_sp.png);
					background-size: 357px auto;
					width: 357px;
					height: 219px;
					top: 422px;
					left: 20px;
				  span { font-size: 28px; }
				}
			}
			.slide{
				#Slide{
					position: relative;
					.slick-list{
						li{
							height: 670px;
							figure{
								min-width: auto;
							}
							//&.slider01 figure { background-image: url("../img/contents/top/main_visu_a_sp.jpg"); }
							//&.slider02 figure { background-image: url("../img/contents/top/main_visu_b_sp.jpg"); }
							//&.slider03 figure { background-image: url("../img/contents/top/main_visu_c_sp.jpg"); }
							&.slider01 figure  { background-image: none; }
							&.slider02 figure  { background-image: none; }
							&.slider03 figure  { background-image: none; }
							&.slider01 figure img { display: block; width: 100%; height: 100%; object-fit: cover; object-position: center center; }
							&.slider02 figure img { display: block; width: 100%; height: 100%; object-fit: cover; object-position: center center; }
							&.slider03 figure img { display: block; width: 100%; height: 100%; object-fit: cover; object-position: center center; }
						}
					}
					.slick-dots{
						position: absolute;
						right: 8px;
						top: 50%;
						transform: translateY(calc(-50% + 16px));
						display: block!important;
						li{
							width: 30px;
							height: 42px;
							//border-radius: 2px;
							//background-color: #FFFFFF;
							text-indent: -9999px;
							margin-bottom: 6px;
							margin-right: 0;

							position: relative;
							background-color: transparent;
							&:last-of-type{
								margin-right: 0;
							}
							&.slick-active{
								//background-color: #87EDA6;
								background-color: transparent;
								button{
									background-color: #87EDA6;
								}
							}
							button{
								border-radius: 2px;
								background-color: #FFFFFF;
								text-indent: -9999px;
								position: absolute;
							    top: 0;
							    right: 50%;
							    transform: translateX(50%);
							    z-index: 70;
							    width: 4px;
							    height: 100%;
							}
						}
					}
				}
			}
		}
		#ImportantBox{
			.innerBasic{
				padding: 0 20px;
			}
			.importantPostWrap{
				padding: 50px 0 35px;
				h3{
					text-align: center;
					padding: 13px 0;
					font-size: 16px;
					margin-bottom: 28px;
				}
				ul{
					li{
						margin-bottom: 10px;
						&:last-of-type{
							margin-bottom: 0;
						}
						.time{
						  font-size: 12px;
						}
						.postItem{
						  margin-bottom: 0;
						  line-height: 1.8;
						  a{
							  font-size: 14px;
						  }
						}
					}
				}
			}
		}
	    #Main{
			.contBox {
				.innerBasic {
				}
			}
			#ContBox01{
				padding:  50px 0 0;
				.titleTypeBasic{
					margin-bottom: 47px;
				}
				.contSubBox01{
				}
				.contSubBox02{
					padding: 58px 0 0;
					.imgBox{
						background-image: url("../img/contents/top/img01_sp.jpg");
						height: 204px;
					}
					.txtBox{
						background-image: url("../img/contents/top/bg01_sp.png");
						background-size: 100% auto;
						background-position: center bottom 45px;
						padding: 27px 20px 38px;
						p{
							font-size: 20px;
							line-height: 1.2;
							margin-bottom: 18px;
						}
						h3{
							font-size: 30px;
							line-height: 1.2;
							margin-bottom: 30px;
						}
						.btnWrap{
						  width: 240px;
						  margin: 0 auto;
						}
					}
				}
			}
			#ContBox02{
				padding:  58px 0 0;
				.titleTypeBasic{
					margin-bottom: 40px;
				}
				.contSubBox{
					padding-bottom: 55px;
					.innerBasic{
					}
					.subBox{
						padding: 0 20px 0;
						margin: 0 0 24px 0;
						h4{
							font-size: 30px;
							margin-bottom: 20px;
							text-align: center;
							background-repeat: no-repeat;
							background-position: center top 65px;
							background-size: 100% auto;
							padding-bottom: 164px;
							width: calc(100% + 80px);
							margin-left: -40px;
							strong{
								font-size: 40px;
							}
						}
						p{
							font-size: 16px;
							margin-bottom: 24px;
						}
						.btnWrap{
							width: 100%;
							margin: 0 auto;
						}
					}
					.slide {
						padding: 0 40px;
				  		.slick-arrow{
							width: 45px;
							height: 45px;
							background-size: 22px auto;
							&.slick-prev{
								left: -20px;
							}
							&.slick-next{
								right: -20px;
							}
						}
						ul {
							position: relative;
							width: 100%;
							height: 100%;
							li {
								width: 100%;
								overflow: hidden;
								text-align: center;
								figure{
									width: 100%;
									height: 100%;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: cover;
									padding-top: 70%;
									position: relative;
									figcaption{
										position: absolute;
										left:16px;
										bottom: 10px;
										color: #fff;
										font-size: 16px;
										font-weight: $medium;
									}
								}
							}
						}
					}
				}
				.contSubBox01{
					.subBox{
						h4{
							background-image: url(../img/contents/top/bg02_sp.jpg); //
						}
					}
					.slide {
						.slick-arrow{
							&.slick-prev{
								background-image: url(../img/contents/arrow_01_g_p.png);
							}
							&.slick-next{
								background-image: url(../img/contents/arrow_01_g_n.png);
							}
						}
						ul{
							li{
								figure{
								}
								&.slider01 figure { background-image: url("../img/contents/top/slide01_01.jpg"); }
								&.slider02 figure { background-image: url("../img/contents/top/slide01_02.jpg"); }
								&.slider03 figure { background-image: url("../img/contents/top/slide01_03.jpg"); }
								&.slider04 figure { background-image: url("../img/contents/top/slide01_04.jpg"); }
								&.slider05 figure { background-image: url("../img/contents/top/slide01_05.jpg"); }
							}
						}
					}
				}
				.contSubBox02{
					padding-bottom: 60px;
					.subBox{
						h4{
							background-image: url(../img/contents/top/bg03_sp.jpg); //
						}
					}
					.slide {
						.slick-arrow{
							&.slick-prev{
								background-image: url(../img/contents/arrow_01_b_p.png);
							}
							&.slick-next{
								background-image: url(../img/contents/arrow_01_b_n.png);
							}
						}
						ul{
							li{
								figure{
								}
								&.slider01 figure { background-image: url("../img/contents/top/slide02_01.jpg"); }
								&.slider02 figure { background-image: url("../img/contents/top/slide02_02.jpg"); }
								&.slider03 figure { background-image: url("../img/contents/top/slide02_03.jpg"); }
								&.slider04 figure { background-image: url("../img/contents/top/slide02_04.jpg"); }
								&.slider05 figure { background-image: url("../img/contents/top/slide02_05.jpg"); }
							}
						}
					}
				}
			}
			#ContBox03{
				.wrap{
					padding: 30px 0 35px;
					background-image: url(../img/contents/top/bg04_sp.png), url(../img/contents/top/bg05_sp.png);
					background-repeat: no-repeat, no-repeat;
					background-size: 235px auto, 370px auto;
					background-position: left top, right bottom;
				}
				.innerBasic {
				}
				.titleTypeBasic{
					margin-bottom: 30px;
				}
				.postType02.disp_pc{
					display: none;
				}
				.btnWrap{
					width: 300px;
					margin: 25px auto 0;
					.btnTypeMore{
					}
				}
			}
			#ContBox04{
				padding:  34px 0 50px;
				.titleTypeBasic{
					margin-bottom: 40px;
				}
				.innerBasic {
					margin: 0 auto;
				}
				.map{
					margin-bottom: 18px;
					iframe{
						width: 100%;
						height: 250px;
					}
				}
				.subBoxWrap{
					.subBox01{
						margin-bottom: 23px;
						h4{
							font-size: 22px;
							margin-bottom: 6px;
						}
					}
					.subBox02{
						margin-bottom: 10px;
						p{
							margin-bottom: 10px;
							font-size: 18px;
						}
						dl{
							dt{
								background-color: #5E5E5E;
								font-size: 14px;
								padding: 5px 15px;
							}
							dd{
								padding: 12px 15px 17px;
							}
						}
					}
					.btnWrap{
						width: 226px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

@media print, screen and (max-width: 389px){
	#Page.pageIndex #MainImg #MainImgInner h2{
		transform: scale(0.8);
		left: -20px
	}
}
@media print, screen and (max-width: 370px){
	#Page.pageIndex #Main #ContBox03 .btnWrap {
		width: 280px;
	}
}


/*
下層共通
-------------------------------------*/
#PageOverview.pageIndex,
#PageCurriculum.pageIndex,
#PageCarrier.pageIndex,
#PageFacilities.pageIndex,
#PageResearch.pageIndex,
#PageFeature.pageIndex,
#PageFeature.pageTheoretical-physics,
#PageFeature.pageExperimental-physics,
#PageResearcher,
#PageTopics,
#PageContact,
#PageEn.pageIndex,
#Page404 {
	#MainImg{
		background-repeat:  no-repeat;
		position: relative;
		&::before{
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0.3;
			z-index: 10;
			background-repeat:  no-repeat;
		}
		&::after{
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(83, 83, 83, 1.0);
			mix-blend-mode: multiply;
		}
		#MainImgInner{
			position: relative;
			.mainImgTitleLower{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 10;
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageOverview.pageIndex,
	#PageCurriculum.pageIndex,
	#PageCarrier.pageIndex,
	#PageFacilities.pageIndex,
	#PageResearch.pageIndex,
	#PageFeature.pageIndex,
	#PageFeature.pageTheoretical-physics,
	#PageFeature.pageExperimental-physics,
	#PageResearcher,
	#PageTopics,
	#PageContact,
	#PageEn.pageIndex,
	#Page404 {
		#MainImg{
			position: relative;
			background-size: cover;
			background-position: center center;
			margin-top: 70px;
			&::before{
				width: 560px;
				height: 219px;
				//background-image: url(../imgB/contents/lower/main_visu_bg_pc);
				background-size: 560px auto;
				background-position: center center;
			}
			&::after{
				height: 300px;
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageOverview.pageIndex,
	#PageCurriculum.pageIndex,
	#PageCarrier.pageIndex,
	#PageFacilities.pageIndex,
	#PageResearch.pageIndex,
	#PageFeature.pageIndex,
	#PageFeature.pageTheoretical-physics,
	#PageFeature.pageExperimental-physics,
	#PageResearcher,
	#PageTopics,
	#PageContact,
	#PageEn.pageIndex,
	#Page404 {
		#MainImg{
			background-size: cover;
			background-position: center center;
			margin-top: 70px;
			&::before{
				width: 100%;
				height: 100%;
				//background-image: url(../imgB/contents/lower/main_visu_bg_sp);
				background-size: 100% auto;
				background-position: center bottom;
			}
			&::after{
				height: 300px;
			}
			#MainImgInner{

			}
		}
	}
}


/*
学科概要
-------------------------------------*/
#PageOverview.pageIndex {
	.contBox{
		.menuGridLabel li figure+ p{
			font-size: 16px;
		}
	}
	.contBox01{
		.contSubBox01{
			.listTypeLinkBtn{
			}
		}
	}
	.contBox02{
		.contSubBox01{
			.wrap{
				dl{
					background-repeat: no-repeat;
					background-size: cover;
					background-position: center center;
					dt{
						color: #fff;
						font-weight: $semibold;
						text-align: center;
					}
					dd{
						p{
							color: #fff;
							font-weight: $regular;
						}
						.btnWrap{
						}
					}
				}
			}
		}
	}
	.contBox03{
		.contSubBox01{
			.wrap{
				.btnTypeImg {
					a{
					}
				}
			}
		}
	}
	.contBox04{
		.contSubBox01{
			.wrap{
				.btnTypeImg {
					a{
					}
				}
			}
		}
	}
	.contBox05{
		.contSubBox01{
			ul{
			}
		}
	}
	.contBox06{
		.contSubBox{
			p{
				font-size: 16px;
			}
			ul{
				li{
					figure{
						img{
							width: 100%;
							height: auto;
						}
					}
				}
			}
		}
	}
	.contBox07{
		.contSubBox01{
			.tableTypeBasic{
				tr{
					td{
						.subBox{
							.titleTypeFlgBlue {
								font-weight: 500;
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageOverview.pageIndex {
		#MainImg{
			background-image: url(../img/contents/overview/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		.contBox{
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			p{
				font-size: 18px;
				margin-bottom: 40px;
			}
		}
		.contBox01{
			margin-bottom: 58px;
			.innerBasic{
				max-width: 1200px;
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 150px;
			.contSubBox01{
				.wrap{
					max-width: 835px;
					margin: 70px auto 0;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					dl{
						width: calc(50% - 20px);
						padding: 110px 55px 80px;
						&:nth-of-type(1){
							background-image: url(../img/contents/overview/photo_04_01.jpg);
						}
						&:nth-of-type(2){
							background-image: url(../img/contents/overview/photo_04_02.jpg);
						}
						dt{
							font-size: 28px;
							margin-bottom: 26px;
						}
						dd{
							p{
								font-size: 18px;
								margin-bottom: 50px;
							}
							.btnWrap{
								width: 175px;
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
		.contBox03{
			padding-bottom: 110px;
			.contSubBox01{
				.wrap{
					.btnTypeImg {
						a{
							//background-image: url("../img/arrow_01.png"), url("../img/contents/overview/img_guide01.jpg");
							b{
								background-image: url("../img/contents/overview/img_guide01.jpg");
							}
						}
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 110px;
			.contSubBox01{
				.wrap{
					.btnTypeImg {
						a{
							//background-image: url("../img/arrow_01.png"), url("../img/contents/overview/img_guide02.jpg");
							b{
								background-image: url("../img/contents/overview/img_guide02.jpg");
							}
						}
					}
				}
			}
		}
		.contBox05{
			padding-bottom: 110px;
			.contSubBox01{
				ul{
				}
			}
		}
		.contBox06{
			padding-bottom: 150px;
			.contSubBox{
				padding: 0 45px;
				p{
					margin-bottom: 10px;
				}
				ul{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					li{
						width: calc(calc(100% / 3) - 15px);
						margin-bottom: 30px
					}
				}
			}
			.contSubBox01{
				margin-bottom: 20px;
			}
		}
		.contBox07{
			padding-bottom: 95px;
			.contSubBox01{
				.tableTypeBasic{
					tr{
						td{
							&>p{
								font-size: 16px;
								margin-bottom: 0;
							}
							.subBox{
								.titleTypeFlgBlue {
									margin-bottom: 14px;
								}
								p{
									margin-bottom: 0;
									font-size: 16px;
								}
							}
						}
						&:nth-of-type(7){
							td{
								.subBox{
									width: 330px;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageOverview.pageIndex {
		#MainImg{
			background-image: url(../img/contents/overview/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		.contBox{
			.innerBasic{
			}
			p{
				font-size: 18px;
				margin-bottom: 36px;
				line-height: 1.9;
			}
		}
		.contBox01{
			.innerBasic{
				max-width: 1200px;
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 40px;
			.contSubBox01{
				.wrap{
					margin: 36px auto 0;
					dl{
						padding: 113px 55px 75px;
						margin-bottom: 20px;
						&:nth-of-type(1){
							background-image: url(../img/contents/overview/photo_04_01.jpg);
						}
						&:nth-of-type(2){
							background-image: url(../img/contents/overview/photo_04_02.jpg);
						}
						dt{
							font-size: 28px;
							margin-bottom: 22px;
						}
						dd{
							p{
								font-size: 18px;
								margin-bottom: 44px;
							}
							.btnWrap{
								width: 175px;
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
		.contBox03{
			padding-bottom: 25px;
			.contSubBox01{
				.wrap{
					.btnTypeImg {
						a{
							b{
								background-image: url("../img/contents/overview/img_guide01_sp.jpg");
							}
						}
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 23px;
			.contSubBox01{
				.wrap{
					.btnTypeImg {
						a{
							b{
								background-image: url("../img/contents/overview/img_guide02_sp.jpg");
							}
						}
					}
				}
			}
		}
		.contBox05{
			padding-bottom: 36px;
			.contSubBox01{
				p{
					margin-bottom: 24px;
				}
				ul{
				}
			}
		}
		.contBox06{
			padding-bottom: 40px;
			.contSubBox{
				p{
					margin-bottom: 30px;
					font-size: 16px;
					line-height: 1.4;
				}
				ul{
					li{
						margin-bottom: 18px;
						figure{
							margin-bottom: 10px;
							img{
								width: 100%;
							}
						}
					}
				}
			}
			.contSubBox01{
				margin-bottom: 36px;
			}
		}
		.contBox07{
			padding-bottom: 53px;
			.contSubBox01{
				.titleTypeBlack{
					margin-top: 40px;
					margin-bottom: 10px;
				}
				.tableTypeBasic{
					tr{
						td{
							&>p{
								font-size: 16px;
								margin-bottom: 0;
							}
							.subBox{
								.titleTypeFlgBlue {
									margin-bottom: 16px;
								}
								p{
									margin-bottom: 0;
									font-size: 16px;
									line-height: 1.5;
								}
							}
						}
						&:nth-of-type(7){
							td{
								.subBox{

								}
							}
						}
					}
				}
			}
		}
	}
}


/*
教育の特徴
-------------------------------------*/
#PageCurriculum.pageIndex {
	.contBox03{
		.contSubBox01{
			.subBox{
				background-color: #FAFAFA;
                .subBoxIn{
                    h4{
                        background-color: #51A1F2;
                        font-weight: $semibold;
                        color: #fff;
                        font-size: 18px;
                    }
                    p{
                        margin-bottom: 0;
                        line-height: 1.5;
                    }
                }
			}
		}
	}
	.contBox04{
		.contSubBox01{
			.flexBox{
		        .txtBox{
				    ul{
				    	border: 1px solid #A8A8A8;
				    }
				}
			}
		}
	}
	.contBox05{
		.contSubBox{
			background-color: #F7F7F7;
			h4{
				font-weight: $bold;
				font-size: 33px;
			}
			h5{
				font-family: $noto-serif;
				font-weight: 700;
				font-size: 23px;
			}
			.subBox01{

			}
			dl{
				background-color: #fff;
				border-radius: 8px;
				dt{
					font-weight: $semibold;
					font-size: 16px;
				}
				dd{
					font-size: 16px;
					border-radius: 8px;
				}
			}
			h6{
				font-size: 16px;
			}
		}
		.contSubBox01{
			h4{
				color: #31C26E;
			}
			dl{
				background-color: #fff;
				dt{
					color: #31C26E;
				}
			}
		}
		.contSubBox02{
			h4{
				color: #51A1F2;
			}
			dl{
				dt{
					color: #51A1F2;
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageCurriculum.pageIndex {
		#MainImg{
			background-image: url(../img/contents/curriculum/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		.contBox{
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			p{
				font-size: 18px;
				margin-bottom: 40px;
			}
		}
		.contBox01{
			margin-bottom: 58px;
			.innerBasic{
				max-width: 1200px;
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 80px;
			.contSubBox01{
				.flexBox{
					margin-bottom: 60px;
			        .txtBox{
			            width: calc(100% - 500px);
			        }
			        figure{
			            width: 450px;
			        }
			        &:nth-of-type(odd){
			        	flex-direction: row;
			        	.txtBox{
			        		order: 1;
			        	}
			        	figure{
			        		order: 2;
			        	}
			        }
			    }
			}
		}
		.contBox03{
			padding-bottom: 135px;
			.contSubBox01{
				&>p{
					margin-bottom: 20px;
				}
				.subBox{
                    padding: 30px 50px;
                    margin-bottom: 50px;
                    .titleTypeFlgBlue{

                    }
                    .subBoxIn{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        &:last-of-type{
                        	margin-bottom: 0;
                        }
                        h4{
                            width: 120px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        p{
                            width: calc(100% - 145px);
                            display: flex;
                            align-items: center;
                            line-height: 1.9;
                            padding: 7px 0;
                        }
                    }
                }
			}
			.contSubBox02{
				small{
					font-size: 12px;
				}
				.flexBox{
					margin-bottom: 80px;
					justify-content: flex-start;
			        .txtBox{
			            width: calc(100% - 600px);
			            margin-right: 50px;
					    .titleTypeBlack{
					    	margin-bottom: 20px;
					    	&+p{
					    		line-height: 1.9;
					    	}
					    }
			        }
			        figure{
			            width: 508px;
			        }
			    }
				.subBox:nth-of-type(3){
					margin-bottom: 120px;
				}
				.subBox:nth-of-type(4){
					.titleTypeBlack{
						margin-bottom: 0;
					}
					.txtBox{
						&>p{
							margin-bottom: 10px;
						}
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 128px;
			.contSubBox01{
				.flexBox{
			        .txtBox{
			            width: calc(100% - 510px);
				    	&>p{
				    		margin-bottom: 25px;
				    	}
					    ul{
					    	max-width: 295px;
					    	margin: 0 auto 34px;
					    	padding: 13px 22px;
					    	.dotTypeBasic{

					    	}
					    }
			        }
			        figure{
			            width: 450px;
			        }
			    }
			}
		}
		.contBox05{
			margin-bottom: 100px;
			.contSubBox{
				padding: 50px 50px;
				h4{
					margin-bottom: 20px;
				}
				h5{
					margin-bottom: 24px;
				}
				.subBox01{
					margin-bottom: 30px;
					.txtBox{
						padding: 0 20px;
						p{
							line-height: 1.9;
							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
				.flexBox{
					.txtBox{
						width: calc(100% - 440px);
					}
					figure{
						width: 385px;
					}
				}
				dl{
					padding: 30px 30px;
					margin-bottom: 38px;
					dt{
						margin-bottom: 10px;
					}
					dd{
					}
				}
				.tableTypeHeadColor{
					margin-bottom: 40px;
				}
				.btnWrap{
					width: 340px;
					margin: 0 auto;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageCurriculum.pageIndex{
		#MainImg{
			background-image: url(../img/contents/curriculum/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		.contBox{
			.innerBasic{
			}
			p{
				font-size: 18px;
				//margin-bottom: 40px;
				line-height: 1.9;
			}
			.titleTypeBlue{
				margin-bottom: 5px;
			}
		}
		.contBox01{
			.innerBasic{
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 10px;
			.contSubBox01{
				.flexBox{
					margin-bottom: 46px;
			        .txtBox{
			        }
			        figure{
			        	margin-bottom: 20px;
			        }
			    }
			}
		}
		.contBox03{
			padding-bottom: 60px;
			.contSubBox01{
				margin-bottom: 40px;
				&>p{
					margin-bottom: 20px;
				}
				.subBox{
                    padding: 30px 20px;
                    margin-bottom: 50px;
                    &:last-of-type{
                    	margin-bottom: 0;
                    }
                    .titleTypeFlgBlue{

                    }
                    .subBoxIn{
                        margin-bottom: 20px;
                        &:last-of-type{
                        	margin-bottom: 0;
                        }
                        h4{
                        	text-align: center;
                        	padding: 6px 0;
                        	margin-bottom: 5px;
                        }
                        p{
                            line-height: 1.9;
                            padding: 10px 0;
                        }
                    }
                }
			}
			.contSubBox02{
				small{
					font-size: 12px;
				}
				.flexBox{
					margin-bottom: 40px;
			        .txtBox{
					    .titleTypeBlack{
					    	margin-bottom: 6px;
					    	&+p{
					    		line-height: 1.9;
					    	}
					    }
			        }
			        figure{
			        }
			    }
				.subBox:nth-of-type(3){
					margin-bottom: 45px;
				}
				.subBox:nth-of-type(4){
					.titleTypeBlack{
						margin-bottom: 10px;
					}
					.txtBox{
						&>p{
							margin-bottom: 23px;
							line-height: 1.0;
						}
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 32px;
			.contSubBox01{
				.flexBox{
			        .txtBox{
				    	&>p{
				    		margin-bottom: 12px;
				    	}
					    ul{
					    	padding: 18px 22px;
					    	margin-bottom: 12px;
					    	.dotTypeBasic{

					    	}
					    }
			        }
			        figure{
			        }
			    }
			}
		}
		.contBox05{
			margin-bottom: 60px;
				.innerBasic{
					&>p{
						margin-bottom: 35px;
					}
				}
			.contSubBox{
				padding: 50px 20px;
				margin-bottom: 40px;
				&:last-of-type{
					margin-bottom: 0;
				}
				h4{
					margin-bottom: 20px;
				}
				h5{
					margin-bottom: 24px;
				}
				.subBox01{
					margin-bottom: 30px;
					.txtBox{
						p{
							line-height: 1.9;
							margin-bottom: 40px;
							&:last-of-type{
								margin-bottom: 28px;
							}
						}
					}
				}
				.flexBox{
					figure{
					}
				}
				dl{
					padding: 30px 20px;
					margin-bottom: 28px;
					dt{
						margin-bottom: 3px;
					}
					dd{
						line-height: 1.5;
					}
				}
				.titleTypeBlack{
					font-size: 16px;
				}
				.ScrollWrap{
					margin-bottom: 30px;
				}
				.tableTypeHeadColor{
					margin-bottom: 27px;
				}
				.btnWrap{
					width: 100%;
					margin: 0 auto;
				}
			}
		}
	}
}


/*
進路について
-------------------------------------*/
#PageCarrier.pageIndex {
	#ContBox03{

		.btnWrap{
            margin: 60px auto 0;
            width: 321px;
        }
        .btnTypeBg{
            .bgErea{
                width: 99px;
                height: auto;
                min-height: 74px;
                display: block;
                background-size: cover;
            }
            .txtErea{
                width: 222px;
                padding-left: 26px;
                padding-right: 55px;
                background-position: right 25px center;
                background-size: 28px auto;
                line-height: 1.5;
                display: flex;
                align-items: center;
                b{
                    font-size: 16px;
                }
                &::before{
                    width: 50px;
                    height: 50px;
                }
                &::after{
                    width: 29px;
                    height: 6px;
                    background-size: 29px auto;
                }
            }
        }
	}
}
@media print, screen and (min-width: 768px) {
	#PageCarrier.pageIndex {
		#MainImg{
			background-image: url(../img/contents/carrier/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		.contBox{
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			p{
				font-size: 18px;
				margin-bottom: 40px;
			}
		}
		.contBox01{
			margin-bottom: 58px;
			.innerBasic{
				max-width: 1200px;
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 140px;
		}
		.contBox03{
			padding-bottom: 115px;
			.contSubBox01{
				padding: 0 21px;
				.subBox{
					.titleTypeFlgEmerald{
						margin-bottom: 14px;
					}
					p{
						line-height: 1.9;
						padding-left: 20px;
						margin-bottom: 29px;
					}
				}
			}
		}
		.contBox04, .contBox05, .contBox06{
			padding-bottom: 120px;
			.contSubBox{
				.flexBox{
					.txtBox{
						width: calc(100% - 500px);
					}
					figure{
						width: 450px;
					}
				}
			}
		}
		.contBox06{
			padding-bottom: 95px;
			.contSubBox01{
				.subBox{
					margin-bottom: 10px;
				}
			}
			.btnWrap{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				.btnTypeBorderBlank{
					width: 265px;
					&:nth-of-type(1) {
						margin-right: 20px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageCarrier.pageIndex {
		#MainImg{
			background-image: url(../img/contents/carrier/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		.contBox{
			.innerBasic{
			}
			p{
				font-size: 18px;
				line-height: 1.9;
			}
			.titleTypeBlue{
				margin-bottom: 5px;
			}
		}
		.contBox02{
			padding-bottom: 55px;
			.contSubBox01{
				&>p{
					margin-bottom: 40px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
		.contBox03{
			padding-bottom: 55px;
			.contSubBox01{
				.subBox{
					margin-bottom: 27px;
					&:last-of-type{
						margin-bottom: 0;
					}
					.titleTypeFlgEmerald{
						margin-bottom: 16px;
					}
					p{
						padding: 0 20px;
						margin-bottom: 0;
					}
				}
			}
			.btnWrap{
				margin-top: 30px;
			}
		}
		.contBox04, .contBox05, .contBox06{
			.contSubBox{
				padding-bottom: 60px;
				.flexBox{
					.txtBox{
						.titleTypeBlue{
							margin-bottom: 30px;
						}
						p{
							margin-bottom: 38px;
						}
					}
				}
			}
		}
		.contBox06{
			padding-bottom: 70px;
			.contSubBox01{
				padding-bottom: 0;
				.subBox{
					margin-bottom: 43px;
				}
			}
			.btnWrap{
				width: 265px;
				margin: 0 auto;
				.btnTypeBorderBlank{
					&:nth-of-type(1) {
						margin-bottom: 26px;
					}
				}
			}
		}
	}
}

@media print, screen and (max-width: 370px) {
	#PageCarrier.pageIndex #ContBox03 .btnWrap{
		width: 100%;
    	max-width: 350px;
	}
	#PageCarrier.pageIndex #ContBox03 .btnTypeBg .txtErea{
	    width: calc(100% - 99px);
	    background-position: right 26px center;
	    background-size: 31px auto;
	}
	#PageCarrier.pageIndex #ContBox03 .btnTypeBg .txtErea b{
		font-size: 15px;
	}
}


/*
世田谷キャンパス
-------------------------------------*/
#PageFacilities.pageIndex {
	.contBox02{
		.contSubBox{
			.flexBox{
				.imgBox{
					.slideTypeDot{
						padding-bottom: 20px;
						position: relative;
						.slider{
							img{
								width: 100%;
								height: auto;
							}
						}
					}
					.slick-dots{
						display: flex;
						flex-wrap: wrap;
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translateX(-50%);
						li{
							text-indent: -9999px;
							background-color: #E0E0E0;
							border-radius: 50%;
							margin-right: 16px;
							width: 9px;
							height: 9px;
							&:last-of-type{
								margin-right: 0;
							}
							&.slick-active{
								background-color: #51A1F2;
							}
						}
					}
				}
			}
		}
	}
	.contBox04{
		.contSubBox01{
			.slide{
				.slideTypeGallery{
					position: relative;
					padding-bottom: 30px;
					.slider{
						margin: 0 10px;
						&:nth-of-type(even){
						}
						img{
							width: 100%;
							height: auto;
						}
					}
					.slick-arrow{
						background-color: #fff;
						background-position: center center;
						background-repeat: no-repeat;
						text-indent: -9999px;
						position: absolute;
						top: 50%;
						transform: translateY(calc(-50% - 10px));
						z-index: 60;
						box-shadow: 0 0 10px rgba(0, 0, 0, .06);
						&.slick-prev{
							background-image: url(../img/contents/arrow_01_g_p.png);
							left: -40px;
						}
						&.slick-next{
							background-image: url(../img/contents/arrow_01_g_n.png);
							right: -40px;
						}
					}
					.slick-dots{
						display: flex;
						flex-wrap: wrap;
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translateX(-50%);
						li{
							text-indent: -9999px;
							background-color: #E0E0E0;
							border-radius: 50%;
							margin-right: 16px;
							width: 9px;
							height: 9px;
							&:last-of-type{
								margin-right: 0;
							}
							&.slick-active{
								background-color: #51A1F2;
							}
						}
					}
				}
			}
		}
	}
	.contBox05{
		.contSubBox01{
			.flexBox{
				figure{
					border: 1px solid #C4C4C4;
					img{
						width: 100%;
						height: auto;
					}
					a{
						display: block;
						height: 100%;
						position: relative;
						&:hover{
							opacity: $opacity;
						}
						&::after{
							content: "";
							display: block;
							width: 40px;
							height: 40px;
							background: url(../img/contents/icon_03.png) no-repeat center center;
							background-size: 15px auto;
							background-color: #1A1A1A;
							position: absolute;
							right: 0;
							bottom: 0;
						}
					}
				}
			}
			.subBox02{
				.box{
					background-color: #F2FCFD;
					h4{
						background-color: #00B9D3;
						color: #fff;
						font-weight: $semibold;
						text-align: center;
					}
					dl{
						color: #000000;
						dt{
							font-weight: $medium;
							font-size: 16px;
						}
						dd{
							font-size: 16px;
						}
					}
					&.box02{
						.wrap{
							dl{
								dd{
									line-height: 1.8;
								}
							}
						}
					}
				}
			}
		}
	}
	.contBox06{
		.contSubBox01 {
			figure{
				border: 1px solid #C4C4C4;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageFacilities.pageIndex{
		#MainImg{
			background-image: url(../img/contents/facilities/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		.contBox{
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			p{
				font-size: 18px;
				margin-bottom: 40px;
			}
		}
		.contBox01{
			margin-bottom: 58px;
			.innerBasic{
				max-width: 1200px;
			}
			.contSubBox01{
				.listTypeLinkBtn{
				}
			}
		}
		.contBox02{
			padding-bottom: 150px;
			.contSubBox{
				margin-bottom: 50px;
				&:last-of-type{
					margin-bottom: 0;
				}
				.flexBox{
					.txtBox{
						width: calc(100% - 560px);
					}
					.imgBox{
						width: 480px;
					}
				}
				&:nth-of-type(even) {
					.flexBox{
						flex-direction: row;
						.txtBox{
							order: 2;
						}
						.imgBox{
							order: 1;
						}
					}
				}
			}
		}
		.contBox03{
			padding-bottom: 120px;
			.menuGridLabel {
				li {
					p{
						font-size: 16px;
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 150px;
			.contSubBox01{
				padding: 0 40px;
				.slide{
					.slideTypeGallery{
						.slider{
							img{
								width: 100%;
								height: auto;
							}
						}
						.slick-arrow{
							width: 60px;
							height: 60px;
							background-size: 30px auto;
							box-shadow: 0 3px 6px 0px rgba(0, 0, 0, .16);
						}
					}
				}
			}
		}
		.contBox05{
			padding-bottom: 150px;
			.contSubBox01{
				.flexBox{
					margin-bottom: 15px;
					figure{
						&:nth-of-type(1){
							width: calc(52% - 5px);
						}
						&:nth-of-type(2){
							width: calc(48% - 5px);
						}
						img{
						}
					}
				}
				.subBox02{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					.box{
						padding: 20px 20px;
						&.box01{
							width: calc(40% - 8px);
							dl{
								padding: 6px 16px 5px;
								dd{
									padding: 0 15px;
								}
							}
						}
						&.box02{
							width: calc(60% - 8px);
							.wrap{
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
								padding: 6px 16px;
								dl{
									&:nth-of-type(1){
										width: 46%;
									}
									&:nth-of-type(2){
										width: 54%;
									}
									dd{
										padding-left: 15px;
									}
								}
							}
						}
						h4{
							padding: 10px 10px;
						}
						dl{
							dd{
								margin-bottom: 10px;
								&:last-of-type{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
		.contBox06{
			padding-bottom: 250px;
			.contSubBox01 {
				figure{
					margin-bottom: 45px;
					a{
						pointer-events: none;
					}
				}
				.btnWrap{
					width: 365px;
					margin: 0 auto;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFacilities.pageIndex{
		#MainImg{
			background-image: url(../img/contents/facilities/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
			.mainImgTitleLower{
				white-space: nowrap;
			}
		}
		.contBox{
			.innerBasic{
			}
			p{
				font-size: 18px;
				line-height: 1.9;
			}
			.titleTypeBlue{
				margin-bottom: 5px;
			}
		}
		.contBox02{
			padding-bottom: 45px;
			.contSubBox{
				margin-bottom: 30px;
				&:last-of-type{
					margin-bottom: 0;
				}
				.flexBox{
					.imgBox{
						.slideTypeDot{
							padding-bottom: 24px;
						}
					}
				}
			}
		}
		.contBox03{
			padding-bottom: 50px;
			.menuGridLabel {
				li {
					margin-bottom: 25px;
					&:last-of-type{
						margin-bottom: 0;
					}
					p{
						font-size: 16px;
						line-height: 1.4;
					}
				}
			}
		}
		.contBox04{
			padding-bottom: 50px;
			.contSubBox01{
				.slide{
					.slideTypeGallery{
						padding: 0 20px 35px;
						.slider{
							margin: auto;
							img{
								width: 100%;
								height: auto;
							}
						}
						.slick-arrow{
							width: 45px;
							height: 45px;
							background-size: 30px auto;
							background-size: 22px auto;
							transform: translateY(calc(-50% - 15px));
							&.slick-prev{
								left: 0;
							}
							&.slick-next{
								right: 0;
							}

						}
						.slick-dots{
							width: 100%;
							justify-content: center;
							li{
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
		.contBox05{
			padding-bottom: 55px;
			.contSubBox01{
				.flexBox{
					margin-bottom: 25px;
					figure{
						margin-bottom: 20px;
						&:last-of-type{
							margin-bottom: 0;
						}
						img{
						}
						a{
							&::after{
								width: 30px;
								height: 30px;
							}
						}
					}
				}
				.subBox02{
					.box{
						padding: 20px 20px;
						margin-bottom: 20px;
						&:last-of-type{
							margin-bottom: 0;
						}
						&.box01{
							dl{
								padding: 10px 12px;
								dd{
									padding-left: 15px;
								}
							}
						}
						&.box02{
							.wrap{
								padding: 10px 12px;
								dl{
									dd{
										padding-left: 15px;
									}
								}
							}
						}
						h4{
							padding: 10px 10px;
							font-size: 16px;
						}
						dl{
							dt{
								margin-bottom: 7px;
								&:last-of-type{
									margin-bottom: 0;
								}
							}
							dd{
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
		.contBox06{
			padding-bottom: 70px;
			.contSubBox01 {
				figure{
					margin-bottom: 30px;
					position: relative;
					&::after{
						content: "";
					    position: absolute;
					    right: 0;
					    bottom: 0;
					    display: block;
					    width: 30px;
					    height: 30px;
					    background: url(../img/contents/icon_03.png) no-repeat center center;
					    background-color: #1a1a1a;
					    background-size: 15px auto;
					}
				}
				.btnWrap{
					width: 100%;
				}
			}
		}
	}
}


/*
研究関係
-------------------------------------*/
#PageResearch.pageIndex {
}
@media print, screen and (min-width: 768px) {
}
@media print, screen and (max-width: 767px) {
}


/*
研究特集
-------------------------------------*/
#PageFeature.pageIndex {
	.contBox02, .contBox03{
		position: relative;
		&::after{
			content: "";
			display: block;
			width: 100%;
			position: absolute;
			left: 0;
			z-index: -1;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
		}
		.titleTypeBasic{
			font-weight: $medium;
			font-size: 40px;
		}
		.contSubBox{
			h4{
				font-family: $noto-serif;
				font-weight: 700;
				font-size: 35px;
			}
		}
		.contSubBox01{
			background-color: #FFF;
		}
		.contSubBox02{
			background-color: #F7F7F7;
		}
	}
	.contBox02{
		.titleTypeBasic{
			color: #31C26E;
		}
		.contSubBox{
			h4{
				color: #31C26E;
			}
		}
		.contSubBox03{
			
		}
	}
	.contBox03{
		.titleTypeBasic{
			color: #51A1F2;
		}
		.contSubBox{
			h4{
				color: #51A1F2;
			}
		}
		.contSubBox01{
			.flexBox{
				figure{
					figcaption{
						font-weight: $bold;
						font-size: 20px;
					}
				}
			}
		}
		.contSubBox03{
			.titleTypeFlgBlue::before{
				width: 10px;
				height: 25px;
			}
		}
	}
	.contBox04{
		background: #CBEEF2;
		background: -moz-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: -webkit-gradient(linear, left top, left bottom, from(#E4F8F1), to(#CBEEF2));
		background: -webkit-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: -o-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		position: relative;
		z-index: 1;
		&::before{
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-position: left top;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
		}
		&::after{
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-position: right bottom;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
		.titleTypeBasic{
			font-weight: $bold;
		}
		/*.contSubBox01{
		}*/
	}
}
@media print, screen and (min-width: 768px) {
	#PageFeature.pageIndex {
		#MainImg{
			background-image: url(../img/contents/feature/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
			.mainImgTitleLower{
				white-space: nowrap;
			}
		}
		.contBox{
			p{
				font-size: 18px;
				line-height: 1.9;
			}
			small{
				font-size: 14px;
				margin-top: -8px;
				display: block;
			}
			.titleTypeBlue{
				margin-bottom: 5px;
			}
		}
		.contBox02, .contBox03{
			padding: 50px 0;
			&::after{
				height: 300px;
				top: 190px
			}
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			.titleTypeBasic{
				margin-bottom: 280px;
			}
			.contSubBox01{
				padding: 90px 0 30px;
				h4{
					text-align: center;
					margin-bottom: 25px;
				}
				.flexBox{
					figure{

					}
					.txtBox{
						width: calc(50% - 50px);
						padding: 35px 0;
					}
				}
			}
			.contSubBox02{
				padding: 70px 100px;
				h4{
					text-align: center;
					margin-bottom: 45px;
				}
			}
			.contSubBox03{
				padding: 35px 0;
				.titleTypeFlgGreen{
					line-height: 1.5;
					padding-left: 20px;
					margin-bottom: 27px;
				}
				.titleTypeFlgBlue{
					line-height: 1.5;
					padding-left: 20px;
					margin-bottom: 27px;
					&::after{
						width: 5px;
		    			height: 23px;
					}
				}
				.menuTypePanel{
					margin-bottom: 30px;
				}
				.btnWrap{
					width: 288px;
					margin: 0 auto;
				}
			}
		}
		.contBox02{
			&::after{
				background-image: url(../img/contents/feature/bg_01_pc.jpg);
			}
			.contSubBox01{
				.flexBox{
					.txtBox{
						p:nth-of-type(2){
							margin-bottom: 40px;
						}
					}
				}
			}
			.contSubBox03{
				.menuTypePanel{
	    			li{
	    				&:nth-of-type(1){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_01.jpg);
	    						}
	    					}
	    				}
						&:nth-of-type(2){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_02.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(3){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_03.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(4){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_04.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(5){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_05.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(6){
	    					a{
	    						b{
	    							//background-image: url(../img/contents/feature/photo_01_06.jpg);
	    						}
	    					}
	    				}
	    			}
	    		}
			}

		}
		.contBox03{
			padding-bottom: 145px;
			&::after{
				background-image: url(../img/contents/feature/bg_02_pc.jpg);
			}
			.contSubBox01{
				.flexBox{
					figure{
						figcaption{
							margin-bottom: 16px;
						}
					}
				}
			}
			.contSubBox03{
				.menuTypePanel{
	    			li{
	    				&:nth-of-type(1){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_01.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(2){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_02.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(3){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_03.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(4){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_04.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(5){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_05.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(6){
	    					a{
	    						b{
	    							//background-image: url(../img/contents/feature/photo_02_06.jpg);
	    						}
	    					}
	    				}
	    			}
	    		}
			}
		}
		.contBox04{
			padding: 90px 0;
			&::before{
				background-image: url(../img/contents/topics/bg_01_pc.png);
				background-size: 516px auto;
				width: 516px;
				height: 489px;
			}
			&::after{
				background-image: url(../img/contents/topics/bg_02_pc.png);
				background-size: 608px auto;
				width: 608px;
				height: 633px;
			}
			.titleTypeBasic{
				font-size: 40px;
				margin-bottom: 60px;
			}
			.innerBasic{
				max-width: 1000px;
			}
			.contSubBox01{
				&>p{
					font-size: 18px;
					margin-bottom: 35px;
					&:last-of-type{
						margin-bottom: 55px;
					}
				}
				.postType04{
					li{
						.title{
			                font-size: 24px;
			            }
			            .subBox{
			            	.imgBoxWrapWrap{
			            		.imgBoxWrap{
			            			.imgBox{
			            				padding-top: 70%;
			            			}
			            		}
			            	}
			            	.txtBox{

			            	}
			            }
					}
				}
				.btnWrap{
					padding: 30px 0;
					.btnTypeMore{
						width: 250px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFeature.pageIndex {
		#MainImg{
			background-image: url(../img/contents/feature/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
			.mainImgTitleLower{
				white-space: nowrap;
			}
		}
		.contBox{
			p{
				font-size: 18px;
				line-height: 1.9;
			}
			small{
				font-size: 14px;
				margin-top: -8px;
				display: block;
				line-height: 2.2;
			}
			.titleTypeBlue{
				margin-bottom: 5px;
			}
		}
		.contBox02, .contBox03{
			padding: 22px 0 45px;
			&::after{
				height: 200px;
				top: 120px;
			}
			.titleTypeBasic{
				font-size: 30px;
				margin-bottom: 200px;
			}
			.contSubBox01{
				padding: 80px 0 30px;
				h4{
					font-size: 30px;
					line-height: 1.6;
					text-align: center;
					margin-bottom: 19px;
				}
				.flexBox{
					figure{

					}
					.txtBox{
						padding: 5px 0;
					}
				}
			}
			.contSubBox02{
				padding: 60px 20px;
				h4{
					font-size: 30px;
					text-align: center;
					margin-bottom: 50px;
				}
				&> p{
					margin-bottom: 35px;
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
			.contSubBox03{
				padding: 35px 0;
				.titleTypeFlgGreen{
					line-height: 1.5;
					padding-left: 20px;
					margin-bottom: 27px;
				}
				.titleTypeFlgBlue{
					line-height: 1.5;
					padding-left: 20px;
					margin-bottom: 27px;
					&::after{
						width: 5px;
		    			height: 23px;
					}
				}
				.menuTypePanel{
					margin-bottom: 30px;
				}
				.btnWrap{
					width: 288px;
					margin: 0 auto;
				}
			}
		}
		.contBox02{
			&::after{
				background-image: url(../img/contents/feature/bg_01_sp.jpg);
			}
			.contSubBox01{
				.flexBox{
					.txtBox{
						p:nth-of-type(2){
							margin-bottom: 40px;
						}
					}
				}
			}
			.contSubBox03{
				.menuTypePanel{
	    			li{
	    				&:nth-of-type(1){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_01_sp.jpg);
	    						}
	    					}
	    				}
						&:nth-of-type(2){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_02_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(3){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_03_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(4){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_04_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(5){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_01_05_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(6){
	    					a{
	    						b{
	    							//background-image: url(../img/contents/feature/photo_01_06_sp.jpg);
	    						}
	    					}
	    				}
	    			}
	    		}
			}
		}
		.contBox03{
			&::after{
				background-image: url(../img/contents/feature/bg_02_sp.jpg);
			}
			.contSubBox01{
				h4{
					margin-bottom: 42px;
				}
				.flexBox{
					figure{
						margin-bottom: 35px;
						figcaption{
							margin-bottom: 16px;
						}
					}
				}
			}
			.contSubBox03{
				.menuTypePanel{
	    			li{
	    				&:nth-of-type(1){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_01_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(2){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_02_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(3){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_03_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(4){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_04_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(5){
	    					a{
	    						b{
	    							background-image: url(../img/contents/feature/photo_02_05_sp.jpg);
	    						}
	    					}
	    				}
	    				&:nth-of-type(6){
	    					a{
	    						b{
	    							//background-image: url(../img/contents/feature/photo_02_06_sp.jpg);
	    						}
	    					}
	    				}
	    			}
	    		}
			}
		}
		.contBox04{
			padding: 43px 0 40px;
			&::before{
				background-image: url(../img/contents/topics/bg_01_pc.png);
				background-size: 234px auto;
				width: 234px;
				height: 300px;
			}
			&::after{
				background-image: url(../img/contents/topics/bg_02_pc.png);
				background-size: 370px auto;
				width: 370px;
				height: 284px;
			}
			.titleTypeBasic{
				font-size: 30px;
				margin-bottom: 45px;
				line-height: 1.2;
				&::after{
					bottom: -17px;
				}
			}
			.contSubBox01{
				&>p{
					font-size: 18px;
					margin-bottom: 35px;
					padding-left: 10px;
					padding-right: 10px;
					&:last-of-type{
						margin-bottom: 25px;
					}
				}
				.postType04{
					li{
						.title{
			                font-size: 24px;
			                line-height: 1.3;
			            }
			            .subBox{
			            	.imgBoxWrapWrap{
			            		.imgBoxWrap{
			            			.imgBox{
			            				padding-top: 70%;
			            			}
			            		}
			            	}
			            	.txtBox{

			            	}
			            }
					}
				}
				.btnWrap{
					padding: 30px 0 0;
					.btnTypeMore{
						width: 240px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}


/*
理論系物理、実験系物理　共通
-------------------------------------*/
#PageFeature.pageTheoretical-physics,
#PageFeature.pageExperimental-physics {
	#Main{
		.contBox{
			position: relative;
			&.notShow{
				display: none;
			}
			.bgBox{
				/*content: "";
				display: block;*/
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-repeat: no-repeat;
				background-position: center top;
				background-size: cover;
				z-index: -1;
			}
			.contSubBox{
				h3{
					color: #fff;
					font-weight: $medium;
					text-align: center;
				}
				.subBox{
					background-color: #F7F7F7;
					.imgBoxWrapWrap{
						.imgBoxWrap{
							.imgBox{
								background-size: cover;
								background-repeat: no-repeat;
								background-position: center center;
							}
						}
					}
					.txtBox{
						h4{
							font-weight: $bold;
						}
						&>p{
							font-weight: $regular;
						}
						.btnWrapWrap{
							.btnWrap{
								.btnTypeArrow{

								}
								.btnTypeSite{

								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageFeature.pageTheoretical-physics,
	#PageFeature.pageExperimental-physics {
		#Main{
			.menuTypePanelScrollWrap{
				padding: 60px 0 130px;
				max-width: 1000px;
				margin: 0 auto;
			}
			.contBox{
				.bgBox{
					height: 448px;
				}
				&:last-of-type{
					padding-bottom: 50px;
				}
				.contSubBox{
					padding-top: 90px;
					padding-bottom: 90px;
					h3{
						font-size: 33px;
						margin-bottom: 40px;
					}
					.subBox{
						max-width: 1100px;
						margin: 0 auto;
						padding: 60px 50px 40px;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						.imgBoxWrapWrap{
							width: 42%;
							.imgBoxWrap{
								.imgBox{
									padding-top: 66%;
								}
							}
						}
						.txtBox{
							width: 52%;
							h4{
								font-size: 20px;
								margin-bottom: 12px;
							}
							&>p{
								font-size: 18px;
								line-height: 1.9;
							}
							.btnWrapWrap{
								margin-top: 30px;
								.btnWrap{
									display: inline-block;
									width: 220px;
									margin-right: 20px;
									margin-bottom: 20px;
									&:last-of-type{
										margin-right: 0;
									}
									.btnTypeArrow{
									}
									.btnTypeSite{
										
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFeature.pageTheoretical-physics,
	#PageFeature.pageExperimental-physics {
		#Main{
			.menuTypePanelScrollWrap{
				padding: 53px 0 60px;
				.innerBasic{
					padding-left: 10px;
					padding-right: 10px;
				}
			}
			.contBox{
				.bgBox{
					height: 248px;
				}
				&:last-of-type{
					padding-bottom: 20px;
				}
				.contSubBox{
					padding-top: 75px;
					padding-bottom: 60px;
					h3{
						font-size: 30px;
						margin-bottom: 28px;
					}
					.subBox{
						padding: 30px 20px 16px;
						.imgBoxWrapWrap{
							margin-bottom: 20px;
							.imgBoxWrap{
								.imgBox{
									padding-top: 66%;
								}
							}
						}
						.txtBox{
							h4{
								font-size: 20px;
								margin-bottom: 12px;
							}
							&>p{
								font-size: 18px;
								line-height: 1.95;
							}
							.btnWrapWrap{
								text-align: center;
								margin-top: 20px;
								.btnWrap{
									display: inline-block;
									width: 230px;
									margin-bottom: 10px;
									&:last-of-type{
										margin-right: 0;
									}
									.btnTypeArrow{
									}
									.btnTypeSite{
										
									}
								}
							}
						}
					}
				}
			}
		}
	}
}



/*
理論系物理
-------------------------------------*/
#PageFeature.pageTheoretical-physics {
	#MainImg{
		background-image: url(../img/contents/feature/theory/mainimage.jpg);
		&::before{
			background-image: url(../img/contents/lower/main_visu_bg_pc.png);
		}
		&::after{
			background-color: rgba(6, 204, 119, 0.5);
		}
		.mainImgTitleLower{
			white-space: nowrap;
		}
	}
	#Main{
		.menuTypePanelScroll{
			li{
				&:nth-of-type(1){
					a{
						b{
							background-image: url(../img/contents/feature/photo_01_01.jpg);
						}
					}
				}
				&:nth-of-type(2){
					a{
						b{
							background-image: url(../img/contents/feature/photo_01_02.jpg);
						}
					}
				}
				&:nth-of-type(3){
					a{
						b{
							background-image: url(../img/contents/feature/photo_01_03.jpg);
						}
					}
				}
				&:nth-of-type(4){
					a{
						b{
							background-image: url(../img/contents/feature/photo_01_04.jpg);
						}
					}
				}
				&:nth-of-type(5){
					a{
						b{
							background-image: url(../img/contents/feature/photo_01_05.jpg);
						}
					}
				}
			}
		}
		.contBox{
		}
		.contBox01{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/theory/photo_01.jpg);
				}
			}
		}
		.contBox02{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/theory/photo_02.jpg);
				}
			}
		}
		.contBox03{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/theory/photo_03.jpg);
				}
			}
		}
		.contBox04{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/theory/photo_04.jpg);
				}
			}
		}
		.contBox05{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/theory/photo_05.jpg);
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageFeature.pageTheoretical-physics {
		#Main{
			.contBox{
				.contSubBox{
					.subBox{
						border-bottom: 4px solid #06CC77;
					}
				}
			}
			.contBox01{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_01.jpg);
				}
			}
			.contBox02{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_02.jpg);
				}
			}
			.contBox03{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_03.jpg);
				}
			}
			.contBox04{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_04.jpg);
				}
			}
			.contBox05{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_05.jpg);
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFeature.pageTheoretical-physics {
		#MainImg{
			background-image: url(../img/contents/feature/theory/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		#Main{
			.contBox{
				.contSubBox{
					.subBox{
						border-bottom: 5px solid #06CC77;
					}
				}
			}
			.contBox01{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_01_sp.jpg);
				}
			}
			.contBox02{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_02_sp.jpg);
				}
			}
			.contBox03{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_03_sp.jpg);
				}
			}
			.contBox04{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_04_sp.jpg);
				}
			}
			.contBox05{
				.bgBox{
					background-image: url(../img/contents/feature/theory/bg_05_sp.jpg);
				}
			}
		}
	}
}


/*
実験系物理
-------------------------------------*/
#PageFeature.pageExperimental-physics {
	#MainImg{
		background-image: url(../img/contents/feature/experimental/mainimage.jpg);
		&::before{
			background-image: url(../img/contents/lower/main_visu_bg_pc.png);
		}
		&::after{
			background-color: rgba(81, 161, 242, 0.5);
		}
		.mainImgTitleLower{
			white-space: nowrap;
		}
	}
	#Main{
		.menuTypePanelScroll{
			li{
				&:nth-of-type(1){
					a{
						b{
							background-image: url(../img/contents/feature/photo_02_01.jpg);
						}
					}
				}
				&:nth-of-type(2){
					a{
						b{
							background-image: url(../img/contents/feature/photo_02_02.jpg);
						}
					}
				}
				&:nth-of-type(3){
					a{
						b{
							background-image: url(../img/contents/feature/photo_02_03.jpg);
						}
					}
				}
				&:nth-of-type(4){
					a{
						b{
							background-image: url(../img/contents/feature/photo_02_04.jpg);
						}
					}
				}
				&:nth-of-type(5){
					a{
						b{
							background-image: url(../img/contents/feature/photo_02_05.jpg);
						}
					}
				}
			}
		}
		.contBox{
		}
		.contBox01{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/experimental/photo_01.jpg);
				}
			}
		}
		.contBox02{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/experimental/photo_02.jpg);
				}
			}
		}
		.contBox03{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/experimental/photo_03.jpg);
				}
			}
		}
		.contBox04{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/experimental/photo_04.jpg);
				}
			}
		}
		.contBox05{
			.contSubBox{
				.imgBox{
					background-image: url(../img/contents/feature/experimental/photo_05.jpg);
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageFeature.pageExperimental-physics {
		#Main{
			.contBox{
				.contSubBox{
					.subBox{
						border-bottom: 4px solid #51A1F2;
					}
				}
			}
			.contBox01{
				.bgBox{
					background-image: url(../img/contents/feature/experimental/bg_01.jpg);
				}
			}
			.contBox02{
				.bgBox{
					background-image: url(../img/contents/feature/experimental/bg_02.jpg);
				}
			}
			.contBox03{
				.bgBox{
					background-image: url(../img/contents/feature/experimental/bg_03.jpg);
				}
			}
			.contBox04{
				.bgBox{
					background-image: url(../img/contents/feature/experimental/bg_04.jpg);
				}
			}
			.contBox05{
				.bgBox{
					background-image: url(../img/contents/feature/experimental/bg_05.jpg);
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageFeature.pageExperimental-physics {
		#MainImg{
			background-image: url(../img/contents/feature/experimental/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		#Main{
			.contBox{
				.contSubBox{
					.subBox{
						border-bottom: 5px solid #51A1F2;
					}
				}
			}
			.contBox01{
				&::after{
					background-image: url(../img/contents/feature/experimental/bg_01_sp.jpg);
				}
			}
			.contBox02{
				&::after{
					background-image: url(../img/contents/feature/experimental/bg_02_sp.jpg);
				}
			}
			.contBox03{
				&::after{
					background-image: url(../img/contents/feature/experimental/bg_03_sp.jpg);
				}
			}
			.contBox04{
				&::after{
					background-image: url(../img/contents/feature/experimental/bg_04_sp.jpg);
				}
			}
			.contBox05{
				&::after{
					background-image: url(../img/contents/feature/experimental/bg_05_sp.jpg);
				}
			}
		}
	}
}


/*
研究者一覧　共通
-------------------------------------*/
#PageResearcher{

}
@media print, screen and (min-width: 768px) {
	#PageResearcher{
		#MainImg{
			background-image: url(../img/contents/staff/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
			.mainImgTitleLower{
				white-space: nowrap;
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageResearcher{
		#MainImg{
			background-image: url(../img/contents/staff/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
			.mainImgTitleLower{
				white-space: nowrap;
			}
		}
	}
}


/*
研究者一覧
-------------------------------------*/
#PageResearcher.pageIndex {
	#Main{
		.contBox{
			.titleTypeBasicLower{

			}
			.contSubBox{
				.postTypeTSL{
			        li{
			            a{
			            }
			            .imgBoxWrap{
			                .imgBox{
			                }
			            }
			            .txtBox{
			                .subBox{
			                    .name{
			                    }
			                    .label{
			                    }
			                }
			                .linkKenkyu{
			                }
			            }
			        }
			    }
			}
			.contSubBox01{
				.postTypeTSL{
			        li{
			        	&:nth-of-type(1){
				            .imgBoxWrap .imgBox{
			                	background-image: url(../img/contents/staff/photo_01.jpg);
				            }
			        	}
			        	&:nth-of-type(2){
				            .imgBoxWrap .imgBox{
			                	background-image: url(../img/contents/staff/photo_02.jpg);
				            }
			        	}
			        	&:nth-of-type(3){
				            .imgBoxWrap .imgBox{
			                	background-image: url(../img/contents/staff/photo_03.jpg);
				            }
			        	}
			        	&:nth-of-type(4){
				            .imgBoxWrap .imgBox{
			                	background-image: url(../img/contents/staff/photo_04.jpg);
				            }
			        	}
			        	&:nth-of-type(5){
				            .imgBoxWrap .imgBox{
			                	background-image: url(../img/contents/staff/photo_05.jpg);
				            }
			        	}
			        }
			    }
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageResearcher.pageIndex {
		#Main{
			.contBox{
				padding: 42px 0 0;
				&:first-of-type{
					padding-top: 120px;
				}
				&:last-of-type{
					padding-bottom: 108px;
				}
				.innerBasic{
					max-width: 1000px;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageResearcher.pageIndex {
		#Main{
			.contBox{
				padding: 42px 0 0;
				&:first-of-type{
					padding-top: 80px;
				}
				&:last-of-type{
					padding-bottom: 56px;
				}
				.titleTypeBasicLower{
					margin-bottom: 30px;
				}
			}
		}
	}
}


/*
研究者詳細
-------------------------------------*/
#PageResearcher.pageEntry {
	.contBox01{
		.contSubBox01{
			.imgBoxWrap{
				.imgBox{
					background-position: left top;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
			.txtBox{
				.subBox01{
                    p{
                    margin-bottom: 0;
	                }
	                .name{
	                    font-weight: $bold;
	                    color: #000000;
	                }
	                .label{
	                    text-align: center;
	                    span{
	                        border: 1px solid #00B9D3;
	                        color: #00B9D3;
	                        font-weight: $regular;
	                        display: inline-block;
	                    }
	                }
                }
                .subBox02{
                	.tableTypeBasic{
                	}
                }
                .subBox03{
                	.btnWrap{
                		.btnTypeSite{
							span{
								background-position: left top calc(50% + 2px);
								letter-spacing: 0em;
							}
                		}
                	}
                }
			}
		}
		.contSubBox02{
			.entry{
				font-weight: $regular;
				.txt{
					p{
						text-align: left;
					}
				}
				&.putImg{
					figure{
						img{
							width: 100%;
							height: auto;
						}
					}
					figcaption{
						text-align: center;
					}
				}
			}
		}
		.contSubBox03{
			dl{
				background-color: #F2FCFD;
				border: 1px solid #00B9D3;
				dt{
					background-color: #00B9D3;
					color: #fff;
					font-weight: $semibold;
					text-align: center;
				}
				dd{
					font-weight: $regular;
				}
			}
		}
		.innerBasic > .btnWrap{
			border-top: 1px solid #C4C4C4;
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageResearcher.pageEntry {
		.contBox01{
			padding: 120px 0;
			.innerBasic{
				max-width: 1000px;
			}
			.contSubBox01{
				display: flex;
		        flex-wrap: wrap;
		        justify-content: space-between;
		        margin-bottom: 50px;
				.imgBoxWrap{
					text-align: center;
					width: 320px;
					max-width: 320px;
					.imgBox{
						padding-top: 392px;
						max-width: 320px;
						margin: 0 auto;
					}
				}
				.txtBox{
					width: calc(100% - 320px);
					padding-left: 60px;
					.subBox01{
						display: flex;
				        flex-wrap: wrap;
				        justify-content: flex-start;
				        margin-bottom: 37px;
	                    p{
		                }
		                .name{
		                	font-size: 24px;
	                        text-align: left;
	                        margin-right: 27px;
		                }
		                .label{
		                	width: 78px;
		                    span{
		                    	font-size: 14px;
	                            line-height: 1.7;
	                            width: 100%;
		                    }
		                }
	                }
	                .subBox02{
	                	.tableTypeBasic{
	                		margin-bottom: 90px;
	                		tr {
	                			th {
	                				width: 163px;
	                				padding: 12px 18px;
	                				border-right: 3px solid #fff;
	    							border-bottom: 3px solid #fff;
	                			}
	                			td {
	                				padding: 12px 15px;
                					border-bottom: 3px solid #fff;
	                			}
	                		}
	                	}
	                }
	                .subBox03{
	                	.btnWrap{
                			display: inline-block;
                			width: 213px;
                			margin-right: 17px;
                			margin-bottom: 10px;
                			&:last-of-type{
                				margin-right: 0;
                			}
	                		.btnTypeSite{
	                		}
	                	}
	                }
				}
			}
			.contSubBox02{
				margin-bottom: 65px;
				.entry{
					margin-bottom: 38px;
					.txt{
						p{
							font-size: 18px;
							line-height: 2.0;
						}
					}
					&.putImg{
						display: flex;
				        flex-wrap: wrap;
				        justify-content: space-between;
				        .txt{
				        	width: 63%;
				        }
						figure{
							width: calc(37% - 70px);
							img{
							}
							figcaption{
								font-size: 14px;
								margin-top: 10px;
							}
						}
					}
				}
			}
			.contSubBox03{
				padding-bottom: 60px;
				dl{
					padding: 50px 50px;
					dt{
						font-size: 20px;
						padding: 10px 10px;
						margin-bottom: 22px;
					}
					dd{
						font-size: 18px;
						line-height: 2.0;
						padding: 0 10px;
					}
				}
			}
			.innerBasic	>.btnWrap{
				.btnTypeToList{
					width: 265px;
					margin: 50px auto 0;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageResearcher.pageEntry {
		.contBox01{
			padding: 80px 0;
			.contSubBox01{
		        margin-bottom: 57px;
				.imgBoxWrap{
					margin-bottom: 20px;
					.imgBox{
						padding-top: 381px;
						max-width: 310px;
						margin: 0 auto;
					}
				}
				.txtBox{
					.subBox01{
						display: flex;
				        flex-wrap: wrap;
				        justify-content: center;
				        margin-bottom: 39px;
	                    p{
		                }
		                .name{
		                	font-size: 24px;
	                        text-align: left;
	                        margin-right: 27px;
		                }
		                .label{
		                	width: 78px;
		                    span{
		                    	font-size: 14px;
	                            line-height: 1.7;
	                            width: 100%;
		                    }
		                }
	                }
	                .subBox02{
	                	.tableTypeBasic{
	                		margin-bottom: 35px;
	                		tr {
	                			th, td{
	                				display: block;
	                			}
	                			th {
	                				padding: 18px 18px;
	                				border-bottom: 3px solid #fff;
	                			}
	                			td {
	                				padding: 18px 20px;
	                				border-bottom: 3px solid #fff;
	                			}
	                		}
	                	}
	                }
	                .subBox03{
	                	.btnWrap{
                			display: block;
                			width: 213px;
                			margin: 0 auto 17px;
                			&:last-of-type{
                				margin-bottom: 0;
                			}
	                		.btnTypeSite{
	                		}
	                	}
	                }
				}
			}
			.contSubBox02{
				margin-bottom: 30px;
				.entry{
					margin-bottom: 36px;
					.txt{
						p{
							font-size: 18px;
							line-height: 1.95;
						}
					}
					&.putImg{
						display: flex;
						flex-wrap: wrap;
				        .txt{
				        	order: 2;
				        	width: 100%;
				        }
						figure{
							order: 1;
							width: 100%;
							margin-bottom: 15px;
							img{
							}
							figcaption{
								font-size: 16px;
								margin-top: 12px;
							}
						}
					}
				}
			}
			.contSubBox03{
				padding-bottom: 50px;
				dl{
					padding: 30px 20px 22px;
					dt{
						font-size: 20px;
						padding: 10px 10px;
						margin-bottom: 22px;
					}
					dd{
						font-size: 18px;
						line-height: 1.95;
					}
				}
			}
			.innerBasic	>.btnWrap{
				.btnTypeToList{
					width: 265px;
					margin: 50px auto 0;
				}
			}
		}
	}
}


/*
受験生の方へ
-------------------------------------*/
#PageExaminee.pageIndex {
    p{
        word-break: break-all;
    }
    img{
        width: 100%;
        height: auto;
    }
    #Header{
        .linkBox02{
            position: absolute;
            border: 1px solid #fff;
            overflow: hidden;
            font-size: 0;
            li{
                display: inline-block;
                font-family: "Oswald", sans-serif;
                font-weight: 400;
                a{
                    position: relative;
                    display: block;
                    text-decoration: none;
                    &::after{
                        content: "";
                        position: absolute;
                    }
                }
                &.jpLink{
                    a{
                        background-color: #51A1F2;
                        text-align: left;
                        color: #fff;
                        &::after{
                            z-index: 2;
                            border-right: 2px solid #fff;
                            transform: rotate(13deg);
                            background-color: #51A1F2;
                        }
                    }
                }
                &.enLink{
                    a{
                        background-color: #F5F5F5;
                        text-align: right;
                        color: #888;
                        &::after{
                            z-index: 1;
                            transform: rotate(13deg);
                            background-color: #F5F5F5;
                        }
                    }
                }
            }
        }
    }
    #MainImg{
        overflow: hidden;
        position: relative;
        padding: 0;
        background-repeat: no-repeat;
        &::before{
            content: "";
            position: absolute;
            left: 0;
            display: block;
            //opacity: .88;
            background-repeat: no-repeat;
        }
        h2{
            position: absolute;
            text-align: left;
            letter-spacing: .1em;
            font-weight: 500;
            color: #fff;
            span{
                display: block;
            }
        }
    }
    #Main{
        a{
            color: #31C1F5;
        }
        .titleTypeSubTitle{
            text-align: center;
            font-weight: 400;
            span{
                position: relative;
                display: inline-block;
                font-family: 'Oswald', sans-serif;
                font-weight: 400;
                color: #5A5A5A;
                &::before, &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    display: block;
                    height: 1px;
                    background-repeat: repeat-x;
                    background-position: center center;
                    background-image: url("../img/contents/parts_01.jpg");
                    background-size: auto 1px;
                }
            }
        }
        .titleTypeBlue{
            font-weight: 400;
            font-size: 18px;
        }
        .titleTypeFlgBlue{
            font-weight: 400;
            font-size: 18px;
            &::before{
                width: 12px;
                height: 30px;
            }
        }
        .titleTypeDoubleBorder{
            border-top: 1px solid #51A1F2;
            border-bottom: 1px solid #51A1F2;
            font-weight: 400;
            font-size: 16px;
            color: #51A1F2;
        }
        .listTypeBasic{
            li{
                line-height: 30px;
                font-size: 16px;
                &::before{
                    content: "・";
                    position: relative;
                    left: 2px;
                    margin-right: 5px;
                }
            }
        }
        p{
            line-height: 30px;
            font-size: 16px;
        }
        .grayBox, .whiteBox{
            .click, .click02{
                position: relative;
                margin-bottom: 0;
                font-size: 22px;
                font-weight: 400;
                &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 22px;
                    height: 22px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url("../img/contents/plus_01_b.png");
                    background-size: 22px auto;
                }
                &.active{
                    &::after{
                        background-image: url("../img/contents/mainus_01_b.png");
                    }
                }
            }
        }
        .grayBox{
            background-color: #F5F5F5;
        }
        .whiteBox{
            background-color: #fff;
            .showBox02{
            	display: none;
            }
        }
        #ContBox01{
            overflow: hidden;
            position: relative;
            background-color: #51A1F2;
            &::after{
                content: "";
                position: absolute;
                display: block;
                background-repeat: no-repeat;
                background-position: center top;
                background-image: url("../img/contents/examinee/txt_01.png");
            }
            .btnTypeBasic {
                span{
                    font-size: 16px;
                    font-weight: 400;
                    &::before{
                        display: none;
                    }
                }
            }
            .contSubBox01{
                width: 295px;
                h3{
                    font-size: 24px;
                    font-weight: 400;
                    color: #fff;
                }
            }
            .post{
                padding: 0;
                background-color: transparent;
                li{
                    border-bottom: none;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    a{
                        text-decoration: none;
                        color: #fff;
                        .subBox{
                            letter-spacing: 0;
                            font-size: 13px;
                        }
                        .title{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        #ContBox02{
            overflow: hidden;
            .subBox{
                .imgBox, .txtBox{
                    position: relative;
                }
                .imgBox{
                    z-index: 1;
                }
                .txtBox{
                    z-index: 0;
                    background: linear-gradient(to bottom,  #e4f8f1 0%,#cbeef2 100%);
                    p{
                        line-height: 30px;
                        font-size: 16px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                    ol{
                        counter-reset: number02;
                        list-style: none;
                        li{
                            position: relative;
                            line-height: 30px;
                            font-size: 16px;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            &::before{
                                counter-increment: number02;
                                content: '0' counter(number02);
                                position: absolute;
                                top: 0;
                                left: 0;
                                font-family: 'Oswald', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                color: #51A1F2;
                            }
                        }
                    }
                }
                &.subBox01{
                    .txtBox{
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
                &.subBox02{
                    .imgBox{
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            counter-reset: number01;
                            li{
                                position: relative;
                                border-top: 1px solid #fff;
                                &:nth-of-type(2n+1){
                                    border-right: 1px solid #fff;
                                    width: 50%;
                                }
                                &:nth-of-type(2n){
                                    width: calc(50% - 1px);
                                }
                                &:nth-of-type(1), &:nth-of-type(2){
                                    border-top: none;
                                }
                                &::before{
                                    counter-increment: number01;
                                    content: '0' counter(number01);
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    display: block;
                                    background-color: #51A1F2;
                                    line-height: 1;
                                    text-align: center;
                                    font-family: 'Oswald', sans-serif;
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .txtBox{
                        margin-right: auto;
                        margin-left: 0;
                    }
                }
            }
        }
        #ContBox03{
            .titleTypeSubTitle{
                &+ p{
                    line-height: 30px;
                    text-align: center;
                    letter-spacing: .1em;
                }
            }
            .contSubBox{
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            .contSubBox01{
                .wrapBox{
                    display: flex;
                    .subBox{
                        cursor: pointer;
                        display: block;
                        text-decoration: none;
                        color: #000;
                        .txtBox{
                            position: relative;
                            border-bottom: 1px solid #000;
                            font-size: 18px;
                            font-weight: 400;
                            &::after{
                                content: "";
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                display: block;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url("../img/contents/arrow_02.png");
                            }
                        }
                    }
                }
            }
            .contSubBox02{
                .grayBox{
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    &.subBox03{
                        padding: 0;
                    }
                }
                h5{
                    font-size: 22px;
                    font-weight: 400;
                }
                .whiteBox{
                    .listTypeLabel{
                        dt{
                            display: inline-block;
                            background-color: #51A1F2;
                            font-weight: 400;
                            font-size: 16px;
                            color: #fff;
                        }
                        dd{
                            line-height: 30px;
                            text-align: justify;
                            font-size: 16px;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            .contSubBox03, .contSubBox04, .contSubBox05, .contSubBox06{
                .subBox{
                    p{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                    .imgBox{
                        figure{
                            width: 100%;
                            figcaption{
                                text-align: center;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
        #ContBox04{
            .contSubBox{
                .subBox{
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    .box{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        #ContBox05{
            background-repeat: no-repeat;
            background-color: #cbeef2;
            .titleTypeSubTitle{
                &+ p{
                    text-align: center;
                }
            }
            .titleTypeBlue{
                font-size: 16px;
            }
            .subBox{
                &:last-of-type{
                    margin-bottom: 0;
                }
                &.subBox01 .box02, &.subBox02 .box05{
                    .tableTypeBasic{
                        tr{
                            th{
                                &[rowspan="3"]{
                                    &+ th{
                                        background-color: #95C9FD;
                                    }
                                }
                            }
                            &:nth-of-type(4), &:nth-of-type(5), {
                                th{
                                    background-color: #95C9FD;
                                }
                            }
                        }
                    }
                }
            }
            .box{
                &:last-of-type{
                    margin-bottom: 0;
                }
                .grayBox{
                    p{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .clikcClose, .clikcClose02{
                cursor: pointer;
                text-align: center;
                font-size: 16px;
                color: #31C1F5;
                &::after{
                    content: "";
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url("../img/contents/icon_05_b.png");
                    background-size: 12px auto;
                }
            }
        }
        #ContBox06{
            counter-reset: number02;
            .titleTypeSubTitle{
                &+ p{
                    text-align: center;
                }
            }
            .listTypeFAQ{
                &:last-of-type{
                    margin-bottom: 0;
                }
                .click{
                    position: relative;
                    &::before{
                        content: "Q" counter(number02);
                        counter-increment: number02;
                        box-sizing: border-box;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #51A1F2;
                        line-height: 1;
                        text-align: center;
                        font-family: "Oswald", sans-serif;
                        font-weight: 400;
                        color: #fff;
                    }
                }
            }
        }
   }
    .modaal-overlay{
        background-color: rgba(255, 255, 255, .8) !important;
    }
    .modaal-wrapper{
        .modaal-container{
            box-shadow: none;
            .modaal-content-container{
                border: 1px solid #C4C4C4;
                background-color: #F2FCFD;
            }
            .modaal-close{
                position: absolute;
                width: 48px;
                height: 48px;
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url("../img/contents/icon_05_modaal.png");
                background-size: 19px auto;
                background-color: #5F5F5F;
                &::after, &::before{
                    display: none;
                }
            }
            .wrapBox{
                .titleTypeBlue{
                    font-weight: 400;
                }
                .imgBox{
                    .titleTypeBlue{
                        font-size: 26px;
                    }
                    ul{
                        li{
                            position: relative;
                            padding-left: 25px;
                            line-height: 30px;
                            font-size: 16px;
                            color: #7C7C7C;
                            &::before{
                                content: "";
                                position: absolute;
                                top: 11px;
                                left: 0;
                                width: 15px;
                                height: 4px;
                                background-color: #51A1F2;
                            }
                        }
                    }
                }
                .txtBox{
                    .titleTypeBlue{
                        line-height: 36px;
                        font-size: 24px;
                    }
                    .whiteBox{
                        background-color: #fff;
                        .subBox{
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                        }
                        .figTypescroll{
                            padding-bottom: 0;
                            h6{
                                line-height: 30px;
                                font-size: 18px;
                                font-weight: 400;
                            }
                            p{
                                line-height: 30px;
                                font-size: 16px;
                                sup{
                                	position: relative;
                                	top: 0px;
                                }
                                &:last-of-type{
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .simplebar-track.simplebar-vertical{
                            border-radius: 90px;
                            top: 11px;
                            width: 9px;
                            background-color: #EDEDED;
                            .simplebar-scrollbar::before{
                                content: "";
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                border-radius: 90px;
                                width: 9px;
                                background: #87eda6;
                                background: linear-gradient(#00b9d3 0%, #87eda6 100%);
                            }
                        }
                    }
                }
            }

        } 
    }
}
@media print, screen and (min-width: 968px) {
    #PageExaminee.pageIndex {
        min-width: auto !important;
        #Header, #Container, #MainImg, #MainImg .innerBasic, #Footer, #Footer .innerBasic{
            width: 100%;
            min-width: auto !important;
        }
        #Header{
            .linkBox02{
                top: 21px;
                right: 86px;
                border-radius: 15px;
                li{
                    font-size: 14px;
                    a{
                        padding-top: 3px;
                        height: 30px;
                        &::after{
                            top: -4px;
                            width: 14px;
                            height: 35px;
                        }
                    }
                    &.jpLink{
                        a{
                            padding-left: 19px;
                            width: 51px;
                            &::after{
                                right: 3px;
                            }
                            &:hover{
                                background-color: #95c9fd;
                                &::after{
                                    background-color: #95c9fd;
                                }
                            }
                        }
                    }
                    &.enLink{
                        a{
                            padding-right: 17px;
                            width: 41px;
                            &::after{
                                left: -11px;
                            }
                            &:hover{
                                background-color: #cecdcd;
                                &::after{
                                    background-color: #cecdcd;
                                }
                            }
                        }
                    }
                }
            }
        }
        #MainImg{
            height: 650px;
            background-position: right top;
            background-image: url("../img/contents/examinee/mainimage.jpg");
            &::before{
                top: -1px;
                width: 712px;
                height: 700px;
                background-position: top right;
                background-image: url("../img/contents/examinee/parts_01.png");
                background-size: 712px auto;
            }
            .innerBasic{
                height: 650px;
                max-width: none;
            }
            h2{
                top: 53%;
                left: 46px;
                transform: translateY(-50%);
                letter-spacing: .1em;
                font-size: 62px;
                span{
                    letter-spacing: .05em;
                    font-size: 32px;
                }
            }
        }
        #Main{
            .innerBasic{
                width: auto;
                max-width: 1040px;
            }
            .titleTypeSubTitle{
                margin-bottom: 32px;
                line-height: 54px;
                font-size: 42px;
                span{
                    font-size: 24px;
                    &::before, &::after{
                        width: 46px;
                    }
                    &::before{
                        right: -60px;
                    }
                    &::after{
                        left: -60px;
                    }
                }
            }
            .titleTypeBasicLower{
                padding-top: 10px;
                padding-bottom: 10px;
                background: linear-gradient(26deg,  #87eda6 4%,#51a1f2 60%,#51a1f2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            .titleTypeFlgBlue{
                margin-bottom: 19px;
                padding-left: 22px;
            }
            .titleTypeDoubleBorder{
                margin-bottom: 17px;
                padding: 10px 0 10px 9px;
            }
            .tableTypeBasic{
                tr{
                    th, td{
                        border-bottom-width: 3px;
                    }
                    th{
                        border-right-width: 3px;
                        font-weight: 400;
                    }
                    td{
                        padding: 16px 19px;
                        line-height: 30px;
                        font-size: 16px;
                    }
                }
            }
            .grayBox, .whiteBox{
                .click, .click02{
                    padding: 18px 67px 18px 31px;
                    &:hover{
                        opacity: .7;
                    }
                    &::after{
                        right: 40px;
                    }
                }
                .showBox, .showBox02{
                    padding: 31px 30px 35px;
                    p{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .whiteBox{
                .click, .click02{
                    &::after{
                        right: 28px;
                    }
                }
            }
            #ContBox01{
                &::after{
                    top: -2px;
                    right: 50%;
                    margin-right: -645px;
                    width: 325px;
                    height: 66px;
                    background-size: 325px auto;
                }
                .innerBasic{
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding-top: 48px;
                    padding-bottom: 59px;
                    &> .btnTypeBasic {
                        display: none;
                    }
                }
                .btnTypeBasic {
                    width: 195px;
                    span{
                        padding-left: 17px;
                        &::after{
                            right: 17px;
                        }
                    }
                    &:hover{
                        opacity: .7;
                    }
                }
                .contSubBox01{
                    width: 295px;
                    h3{
                        margin-bottom: 40px;
                        padding-left: 14px;
                    }
                }
                .post{
                    width: calc(100% - 295px);
                    li{
                        margin-bottom: 18px;
                        a{
                            display: flex;
                            align-items: center;
                            flex-wrap: nowrap;
                            /*.subBox, .title{
                                display: inline-block;
                            }*/
                            .subBox{
                                position: relative;
                                margin-right: 15px;
                                width: 109px;
                                letter-spacing: 0;
                                &::after{
                                    content: "";
                                    position: absolute;
                                    top: 4px;
                                    right: 0;
                                    display: block;
                                    width: 1px;
                                    height: 15px;
                                    background-color: #fff;
                                }
                            }
                            .title{
                                width: calc(100% - 109px);
                                margin-bottom: 0;
                            }
                            &:hover{
                                .title{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
            #ContBox02{
                .innerBasic{
                    padding-top: 98px;
                    max-width: 1240px;
                }
                .subBox{
                    .imgBox{
                        width: 620px;
                    }
                    .txtBox{
                        width: calc(100% - 340px);
                        &::after{
                            content: "";
                            position: absolute;
                            display: block;
                            width: calc(100% - 340px);
                            background-repeat: no-repeat;
                            background-position: left top;
                        }
                        p{
                            margin-bottom: 28px;
                        }
                        ol{
                            li{
                                padding-left: 30px;
                                margin-bottom: 16px;
                                &::before{
                                    padding-right: 14px;
                                }
                            }
                        }
                    }
                    &.subBox01{
                        .imgBox{
                            margin-right: auto;
                            margin-left: 0;
                        }
                        .txtBox{
                            top: -302px;
                            padding: 86px 50px 89px 334px;
                            &::after{
                                top: -142px;
                                left: 282px;
                                width: 650px;
                                height: 229px;
                                background-image: url("../img/contents/examinee/wall_01.png");
                                background-size: 650px auto;
                            }
                        }
                    }
                    &.subBox02{
                        margin-top: -237px;
                        .imgBox{
                            margin-right: 0;
                            margin-left: auto;
                            ul{
                                li{
                                    &::before{
                                        padding-top: 11px;
                                        width: 36px;
                                        height: 25px;
                                    }
                                }
                            }
                        }
                        .txtBox{
                            top: -394px;
                            padding: 95px 332px 89px 100px;
                            &::after{
                                bottom: -165px;
                                left: 6px;
                                width: 650px;
                                height: 274px;
                                background-image: url("../img/contents/examinee/wall_02.png");
                                background-size: 650px auto;
                            }
                        }
                    }
                }
            }
            #ContBox03{
                margin-top: -352px;
                .innerBasic{
                    padding-top: 62px;
                    padding-bottom: 30px;
                }
                .titleTypeSubTitle{
                    &+ p{
                        margin-bottom: 53px;
                    }
                }
                .contSubBox{
                    margin-bottom: 69px;
                    .subBox{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                }
                .contSubBox01{
                    .titleTypeBasicLower{
                        margin-bottom: 31px;
                    }
                    .wrapBox{
                        .subBox{
                            margin-right: 20px;
                            padding: 0;
                            width: calc((100% - 60px) / 4);
                            &:hover{
                                opacity: .7;
                            }
                            &:nth-of-type(4n){
                                margin-right: 0;
                            }
                            .txtBox{
                                padding: 13px 25px 13px 0;
                                &::after{
                                    right: 9px;
                                    width: 10px;
                                    height: 13px;
                                    background-size: 9px auto;
                                }
                            }
                        }
                    }
                }
                .contSubBox02{
                    .grayBox{
                        margin-bottom: 33px;
                        padding: 30px 30px 35px;
                        &.subBox02{
                            figure{
                                margin-top: -34px;
                            }
                        }
                        &.subBox03{
                            figure{
                                margin-top: -83px;
                                margin-bottom: -16px;
                            }
                        }
                        &.subBox03{
                            .showBox, .showBox02{
                                padding: 31px 30px 35px;
                            }
                        }
                    }
                    h5{
                        margin-bottom: 49px;
                    }
                    .whiteBox{
                        margin-top: 34px;
                        padding: 29px 33px 39px;
                        .titleTypeBlue{
                            margin-bottom: 20px;
                        }
                        .listTypeLabel{
                            dt{
                                margin-bottom: 7px;
                                padding: 4px 21px;
                            }
                            dd{
                                margin-bottom: 17px;
                            }
                        }
                    }
                }
                .contSubBox03, .contSubBox04, .contSubBox05, .contSubBox06{
                    .titleTypeBasicLower{
                        margin-bottom: 30px;
                    }
                    .listTypeBasic{
                        margin-bottom: 24px;
                    }
                    .subBox{
                        p{
                            margin-bottom: 29px;
                        }
                        .txtBox{
                            padding-right: 40px;
                            width: 53%;
                        }
                        .imgBox{
                            position: relative;
                            left: 20px;
                            width: 47%;
                            figure{
                                figcaption{
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
                .contSubBox06{
                    img{
                        margin: 27px auto 0;
                        max-width: 716px;
                    }
                }
            }
            #ContBox04{
                .innerBasic{
                    padding-top: 45px;
                    padding-bottom: 107px;
                }
                .contSubBox{
                    .subBox{
                        margin-bottom: 47px;
                        padding-right: 20px;
                        padding-left: 20px;
                        p{
                            &+ .box{
                                margin-top: 28px;
                            }
                            &+ .tableTypeBasic{
                                margin-top: -13px;
                            }
                        }
                        .box{
                            margin-bottom: 38px;
                        }
                        .tableTypeBasic{
                            max-width: 500px;
                            tr{
                                th{
                                    padding-right: 5px;
                                    padding-left: 5px;
                                    text-align: center;
                                    width: 24%; 
                                }
                            }
                        }
                    }
                }
                .contSubBox01{
                    .subBox01{
                        p{
                            &:first-of-type{
                                margin-bottom: 28px;
                            }
                        }
                    }
                }
            }
            #ContBox05{
                background-position: center top, center bottom, center top;
                background-image: url("../img/contents/examinee/wall_03_01.png"), url("../img/contents/examinee/wall_03_02.png"), linear-gradient(#e4f8f1 0%, #cbeef2 100%);
                background-size: 1500px auto, 1500px auto, auto;
                .innerBasic{
                    padding-top: 35px;
                    padding-bottom: 58px;
                }
                .titleTypeSubTitle{
                    &+ p{
                        margin-bottom: 34px;
                    }
                }
                .titleTypeBlue{
                    //margin-bottom: 30px;
                    margin-bottom: 23px;
                }
                .tableTypeBasic{
                    tr{
                        th{
                            padding-right: 5px;
                            padding-left: 5px;
                            text-align: center;
                        }
                    }
                }
                .subBox{
                    margin-bottom: 30px;
                    &.subBox01 .box02, &.subBox02 .box05{
                        .tableTypeBasic{
                            tr{
                                th{
                                    &[rowspan="3"]{
                                        &:nth-of-type(1){
                                            width: 4.5%; 
                                        }
                                    }
                                }
                                &:nth-of-type(1){
                                    th{
                                        width: 14.5%; 
                                    }
                                }
                            }
                        }
                    }
                }
                .box{
                    margin-bottom: 28px;
                    .grayBox{
                        padding: 16px 21px;
                    }
                    &.box01{
                        .tableTypeBasic{
                            max-width: 520px;
                            tr{
                                th{
                                    width: 27%; 
                                }
                            }
                        }
                    }
                }
                .clikcClose, .clikcClose02{
                    margin-top: 50px;
                    &:hover{
                        text-decoration: underline;
                    }
                    &::after{
                        margin-left: 12px;
                    }
                }
            }
            #ContBox06{
                .innerBasic{
                    padding-top: 67px;
                    padding-bottom: 118px;
                }
                .titleTypeSubTitle{
                    &+ p{
                        margin-bottom: 32px;
                    }
                }
                .listTypeFAQ{
                    margin-bottom: 30px;
                    .click, .click02{
                        padding-top: 23px;
                        padding-left: 94px;
                        padding-bottom: 23px;
                        font-size: 18px;
                        &::before{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 72px;
                            height: 100%;
                            font-size: 24px;
                        }
                    }
                    .showBox, .showBox02{
                        padding: 26px 42px 35px 31px;
                    }
                }
            }
        }
        .modaal-wrapper{
            .modaal-inner-wrapper{
                padding-right: 20px;
                padding-left: 20px;
            }
            .modaal-container{
                max-width: 1200px;
                .modaal-content-container{
                    padding: 77px 58px 73px 59px;
                }
                .modaal-close{
                    top: -24px;
                    right: -18px;
                    &:hover{
                        opacity: .7;
                    }
                }
                .wrapBox{
                    display: flex;
                    .imgBox{
                        width: 318px;
                        .titleTypeBlue{
                            margin-top: 21px;
                            margin-bottom: 27px;
                        }
                    }
                    .txtBox{
                        margin-left: 50px;
                        width: calc(100% - 368px);
                        .titleTypeBlue{
                            margin-bottom: 29px;
                        }
                        .whiteBox{
                            padding: 29px 31px 40px 30px;
                            .subBox{
                                margin-bottom: 40px;
                            }
                            .figTypescroll{
                                padding-right: 45px;
                                height: 504px;
                                h6{
                                    margin-bottom: 14px;
                                }
                                p{
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }

            } 
        }
    }
}
@media print, screen and (min-width: 768px) {
    #PageExaminee.pageIndex {
        padding-left: 200px;
        #Header{
            position: fixed;
            top: 0;
            left: 200px;
            width: calc(100% - 200px) !important;
            background-color: transparent;
            h1{
                display: none;
            }
        }
        #GlobalNav{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 50;
            width: 200px;
            min-width: 200px;
            height: 100%;
            background-color: #fff;
            overflow-scrolling: touch;
            -webkit-overflow-scrolling: touch;
            overscroll-behavior-y: contain;
            scrollbar-width: none;
            -ms-overflow-style: none;
            ::-webkit-scrollbar {
                display: none;
            }
            .innerBasic{
                padding: 0 !important;
                width: 200px;
            }
            .naviLogo{
                margin-bottom: 44px;
                padding: 51px 0 0 10px;
                a{
                    display: block;
                    width: 180px;
                    height: 123px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url("../img/contents/examinee/logo.png");
                    background-size: 180px;
                    text-indent: -9999px;
                }
            }
            .navMenuWrap{
                border-top: 1px solid #DBDBDB;
                li{
                    border-bottom: 1px solid #DBDBDB;
                    a{
                        position: relative;
                        display: block;
                        padding: 20px 0 20px 34px;
                        text-decoration: none;
                        color: #000;
                        letter-spacing: .1em;
                        font-weight: 400;
                        font-size: 16px;
                        &:hover{
                            opacity: .7;
                        }
                        &::before{
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            background-repeat: no-repeat;
                            background-position: top left;
                            background: linear-gradient(45deg,  #90e3c0 0%,#7db9e8 100%);

                        }
                    }
                }
            }
        }
    }
}
@media print, screen and (max-width: 1240px) {
    #PageExaminee.pageIndex {
        #Main{
            .innerBasic{
                padding-right: 20px;
                padding-left: 20px;
            }
            #ContBox02{
                .innerBasic{
                    padding-right: 0;
                    padding-left: 0;
                    max-width: 1040px;
                }
            }
        }
    }
}
@media print, screen and (max-width: 1100px) {
    #PageExaminee.pageIndex {
        #MainImg{
            margin-top: 70px;
            height: 500px;
            background-position: center top;
            background-image: url("../img/contents/examinee/mainimage_sp.jpg");
            &::before{
                top: auto;
                bottom: -95px;
                left: 0;
                width: 100%;
                height: 398px;
                background-position: top left;
                background-image: url("../img/contents/examinee/parts_01_sp.png");
                background-size: cover;
            }
            .innerBasic{
                height: 500px;
            }
            h2{
                top: auto;
                bottom: 0;
                left: 0;
                padding: 0 0 24px 20px;
                line-height: 58px;
                letter-spacing: .1em;
                font-size: 48px;
                span{
                    margin-bottom: 8px;
                    line-height: 38px;
                    letter-spacing: .05em;
                    font-size: 26px;
                }
            }
        }
        #Main{
            #ContBox02{
                overflow: visible;
                .innerBasic{
                    padding-top: 63px;
                    max-width: none;
                }
                .subBox{
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        z-index: 0;
                        display: block;
                        width: 100%;
                        background-repeat: no-repeat;
                        background-position: center top;
                    }
                    .imgBox{
                        width: 100%;
                    }
                    .txtBox{
                        width: calc(100% - 20px);
                        padding: 29px 20px 28px !important;
                        &::after{
                            display: none;
                        }
                        p{
                            margin-bottom: 28px;
                        }
                        ol{
                            li{
                                margin-bottom: 8px;
                                padding-left: 32px;
                                line-height: 25px;
                                font-size: 14px;
                                &::before{
                                    padding-right: 15px;
                                }
                            }
                        }
                    }
                    &.subBox01{
                        margin-bottom: 20px;
                        &::after{
                            top: -57px;
                            left: 0;
                            height: 104px;
                            background-image: url("../img/contents/examinee/wall_01_sp.png");
                            background-size: 387px auto;
                        }
                        .imgBox{
                            margin-right: auto;
                            margin-left: 0;
                        }
                        .txtBox{
                            top: 0;
                        }
                    }
                    &.subBox02{
                        margin-top: 0;
                        &::after{
                            bottom: -223px;
                            left: -33px;
                            height: 274px;
                            background-image: url("../img/contents/examinee/wall_02_sp.png");
                            background-size: 335px auto;
                        }
                        .imgBox{
                            margin-right: 0;
                            margin-left: auto;
                            ul{
                                li{
                                    letter-spacing: 0;
                                    &::before{
                                        padding-top: 5px;
                                        width: 27px;
                                        height: 22px;
                                    }
                                }
                            }
                        }
                        .txtBox{
                            top: 0;
                            padding-right: 10px;
                        }
                    }
                }
            }
            #ContBox03{
                margin-top: 0;
            }
            #ContBox05{
                .subBox{
                    &.subBox01 .box02, &.subBox02 .box05{
                        .tableTypeBasic{
                            display: block;
                            tbody, tr, th, td{
                                display: block;
                                width: 100%;
                            }
                            tr{
                                th{
                                    border-right: none;
                                    padding-left: 20px;
                                    width: 100% !important;
                                    text-align: left;
                                    font-size: 16px;
                                    &[rowspan="3"]{
                                        .pc_display{
                                            display: none;
                                        }
                                        &+ th{
                                            padding-top: 13px;
                                            padding-bottom: 13px;
                                        }
                                    }
                                }
                                &:nth-of-type(4), &:nth-of-type(5), {
                                    th{
                                        padding-top: 13px;
                                        padding-bottom: 13px;
                                    }
                                }
                                td{
                                    padding: 15px 20px;
                                    text-align: justify;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media print, screen and (max-width: 967px) {
    #PageExaminee.pageIndex {
        #Header{
            .linkBox02{
                top: 24px;
                right: 76px;
                border-radius: 12px;
                li{
                    font-size: 12px;
                    a{
                        padding-top: 3px;
                        height: 24px;
                        &::after{
                            top: -4px;
                            height: 35px;
                        }
                    }
                    &.jpLink{
                        a{
                            padding-left: 11px;
                            width: 41px;
                            &::after{
                                right: 3px;
                                width: 10px;
                            }
                        }
                    }
                    &.enLink{
                        a{
                            padding-right: 15px;
                            width: 31px;
                            &::after{
                                left: -11px;
                                width: 14px;
                            }
                        }
                    }
                }
            }
        }
        #Footer{
          position: relative;
          /*#PageTop{
              width: 45px;
              height: 45px;
              a{
                padding-top: 24px;
                font-size: 10px;
                background-size: 14px auto;
                background-position: center top 10px;
              }
            }*/
            .footerSection01{
                padding-bottom: 40px;
                .footerMenuCont{
                    padding-left: 0;//
                    max-width: none;//
                    margin: auto;//
                    .footerMenuWrap{
                        display: block;//
                        .footerMenu01{
                            display: block;//
                            width: 100%;//

                            padding: 42px 0 0;
                            .footerMenu_list{
                                border-bottom: 1px solid #8F8F8F;
                                margin-bottom: 21px;

                                .menuListItem{
                                    margin-bottom: 18px;
                                    a{

                                    }
                                }
                                &:nth-of-type(2), &:nth-of-type(3){
                                    .menuListItem{
                                        margin-bottom: 18px;
                                        &.menuListItemLower{
                                            margin-bottom: 12px;
                                            &:last-of-type{
                                                margin-bottom: 18px;
                                            }
                                        }
                                    }
                                }
                                &:last-of-type{
                                    border-bottom: none;
                                    .menuListItem{
                                        border-bottom: 1px solid #8F8F8F;
                                        padding-bottom: 16px;
                                        &:last-of-type{
                                            border-bottom: none;
                                        }
                                    }
                                }
                                .menuListItemLower{
                                    font-size: 12px;
                                    margin-bottom: 12px;

                                }
                            }
                        }
                        .footerMenu02{
                            width: 100%;//
                            .footerBtnBox{
                                margin-top: 0;//
                                li{
                                    width: 100%;
                                    max-width: 350px;
                                }
                                .btnTypeBg{
                                    .bgErea{
                                    }
                                    .txtErea{
                                        width: calc(100% - 99px);
                                        padding-left: 56px;
                                        background-position: right 26px center;
                                        background-size: 31px auto;
                                        b{
                                            font-size: 16px;
                                        }
                                        &::before{
                                            left: 27px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .footerSection02{
                background-image: url("../img/footer/img_bg_sp.png");
                background-color: #222222;
                padding: 40px 0 30px;
                .innerBasic{
                    max-width: none; //
                    margin: auto; //
                    position: static; //
                }
                .footerInfo01{
                    display: block;//

                    .footerLogo{
                        a{
                            margin: auto; //
                            margin-bottom: 5px;
                        }
                    }
                    .wrap{
                        padding: 4px;
                        margin-bottom: 25px;
                        h2{
                            text-align: center;
                        }
                        p{
                            margin-left: 0; //

                            text-align: center;
                        }
                    }
                }
                .footerInfo02{
                    position: static; //
                    .footerMenuLink{
                        justify-content: center;
                        li{
                            margin-right: 40px;
                            &:last-of-type{
                                margin-right: 0;
                            }
                            .btnTypeBlank{
                            }
                        }
                    }
                }
            }
          #CopyRight{
            letter-spacing: 0.11em;
            font-size: 10px;
            padding: 22px 0;
            small, address{
              display: inline-block;
            }
          }
        }
        #Main{
            .titleTypeSubTitle{
                margin-bottom: 38px;
                line-height: 51.5px;
                font-size: 42px;
                span{
                    line-height: 1;
                    font-size: 24px;
                    &::before, &::after{
                        top: 56%;
                        width: 46px;
                    }
                    &::before{
                        right: -59px;
                    }
                    &::after{
                        left: -59px;
                    }
                }
            }
            .titleTypeBasicLower{
                margin-bottom: 31px;
                padding-top: 13px;
                padding-bottom: 13px;
                line-height: 32px;
                background: linear-gradient(75deg, #87EDD6 13%, #51a1f2 61%, #51a1f2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            .titleTypeBlue{
                line-height: 30px;
            }
            .titleTypeFlgBlue{
                margin-bottom: 21px;
                padding-left: 22px;
            }
            .titleTypeDoubleBorder{
                margin-bottom: 16px;
                padding: 10px 0 10px 9px;
            }
            .tableTypeBasic{
                display: table;
                tr{
                    display: table-row;
                    th, td{
                        display: table-cell;
                        border-bottom-width: 3px;
                    }
                    th{
                        padding-right: 5px;
                        padding-left: 5px;
                        border-right: 4px solid #fff;;
                        font-weight: 400;
                        font-size: 14px;
                    }
                    td{
                        padding: 15px 11px;
                        line-height: 30px;
                        font-size: 14px;
                    }
                }
            }
            .listTypeBasic{
                margin-bottom: 24px;
            }
            .grayBox, .whiteBox{
                .click, .click02{
                    padding: 18px 67px 18px 31px;
                    &::after{
                        right: 22px;
                    }
                }
                .showBox, .showBox02{
                    padding: 31px 20px 27px;
                    p{
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .grayBox{
                .click, .click02{
                    padding: 18px 67px 18px 31px;
                }
            }
            .whiteBox{
                .click{
                    padding: 19px 67px 19px 31px;
                    &::after{
                        right: 20px;
                    }
                }
                .showBox{
                    padding: 27px 20px 27px;
                }
            }
            .txtBox{
                width: 100%;
            }
            #ContBox01{
                &::after{
                    top: -2px;
                    left: 68px;
                    width: 325px;
                    height: 66px;
                    background-size: 325px auto;
                }
                .innerBasic{
                    padding-top: 50px;
                    padding-bottom: 50px;
                }
                .btnTypeBasic {
                    margin: 0 auto;
                    padding: 10px 12px;
                    width: 195px;
                    span{
                        padding-left: 17px;
                        &::after{
                            right: 17px;
                        }
                    }
                }
                .contSubBox01{
                    width: 295px;
                    h3{
                        margin-bottom: 30px;
                        padding-left: 3px;
                    }
                    &> .btnTypeBasic {
                        display: none;
                    }
                }
                .post{
                    margin-bottom: 36px;
                    li{
                        border-bottom: 1px solid #fff;
                        &:first-of-type{
                            a{
                                padding-top: 0;
                            }
                        }
                        &:last-of-type{
                            border-bottom: none;
                            a{
                                padding-bottom: 0;
                            }
                        }
                        a{
                            display: block;
                            padding-top: 31px;
                            padding-bottom: 27px;
                            .subBox{
                                margin-bottom: 6px;
                                letter-spacing: 0;
                            }
                            .title{
                                margin-bottom: 0;
                                line-height: 25px;
                            }
                        }
                    }
                }
            }
            #ContBox03{
                .innerBasic{
                    padding-top: 32px;
                    padding-bottom: 37px;
                }
                .titleTypeSubTitle{
                    &+ p{
                        margin-bottom: 47px;
                    }
                }
                .contSubBox{
                    margin-bottom: 62px;
                }
                .contSubBox01{
                    .titleTypeBasicLower{
                        margin-bottom: 31px;
                    }
                    .wrapBox{
                        flex-wrap: wrap;
                        .subBox{
                            margin-right: 14px;
                            margin-top: 20px;
                            width: calc((100% - 14px) / 2);
                            &:nth-of-type(1), &:nth-of-type(3){
                                margin-top: 0;
                            }
                            &:nth-of-type(3n){
                                margin-right: 0;
                            }
                            &.subBox02, &.subBox03{
                                margin-right: 0;
                            }
                            
                            .txtBox{
                                padding: 9px 25px 9px 0;
                                font-size: 16px;
                                &::after{
                                    right: 9px;
                                    width: 5px;
                                    height: 7px;
                                    background-size: 5px auto;
                                }
                            }
                        }
                    }
                }
                .contSubBox02{
                    .grayBox{
                        margin-bottom: 33px;
                        padding: 26px 20px 27px;
                        &.subBox02{
                            figure{
                                margin-top: -10px;
                            }
                        }
                        &.subBox03{
                            figure{
                                margin-top: -57px;
                                margin-bottom: -16px;
                            }
                        }
                        &.subBox03{
                            .click{
                                padding: 18px 67px 18px 31px;
                                &::after{
                                    right: 22px;
                                }
                            }
                            .showBox{
                                padding: 31px 20px 27px;
                            }
                        }
                    }
                    h5{
                        margin-bottom: 29px;
                        padding-left: 10px;
                    }
                    .whiteBox{
                        margin-top: 29px;
                        padding: 26px 21px 28px;
                        .titleTypeBlue{
                            margin-bottom: 20px;
                        }
                        .listTypeLabel{
                            dt{
                                margin-bottom: 7px;
                                padding: 4px 21px;
                            }
                            dd{
                                margin-bottom: 17px;
                            }
                        }
                    }
                }
                .contSubBox03, .contSubBox04, .contSubBox05, .contSubBox06{
                    .titleTypeBasicLower{
                        margin-bottom: 30px;
                    }
                    .listTypeBasic{
                        margin-bottom: 24px;
                    }
                    .subBox{
                        p{
                            margin-bottom: 29px;
                        }
                        .txtBox{
                            margin-bottom: 29px;
                        }
                        .imgBox{
                            figure{
                                figcaption{
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
                .contSubBox06{
                    img{
                        margin: 7px auto 0;
                    }
                }
            }
            #ContBox04{
                .innerBasic{
                    padding-bottom: 48px;
                }
                .contSubBox{
                    .subBox{
                        margin-bottom: 47px;
                        p{
                            &+ .box{
                                margin-top: 28px;
                            }
                            &+ .tableTypeBasic{
                                margin-top: -16px;
                            }
                        }
                        .box{
                            margin-bottom: 25px;
                        }
                        .tableTypeBasic{
                            tr{
                                th{
                                    width: 23%; 
                                }
                            }
                        }
                    }
                }
                .contSubBox01{
                    .subBox01{
                        p{
                            &:first-of-type{
                                margin-bottom: 32px;
                            }
                        }
                    }
                }
            }
            #ContBox05{
                background-position: center top, right bottom, center top;
                background-image: url("../img/contents/examinee/wall_03_01_sp.png"), url("../img/contents/examinee/wall_03_02_sp.png"), linear-gradient(#e4f8f1 0%, #cbeef2 100%);
                background-size: 790px auto, 302px auto, auto;
                .innerBasic{
                    padding-top: 30px;
                    padding-bottom: 52px;
                }
                .titleTypeSubTitle{
                    span{
                        font-size: 20px;
                        &::before, &::after{
                            width: 20px;
                        }
                        &::before{
                            right: -28px;
                        }
                        &::after{
                            left: -28px;
                        }
                    }
                    &+ p{
                        margin-bottom: 27px;
                    }
                }
                .titleTypeBlue{
                    margin-bottom: 13px;
                }
                .subBox{
                    margin-bottom: 30px;
                    &.subBox01 .box02, &.subBox02 .box05{
                        .tableTypeBasic{
                            display: block;
                            tbody, tr, th, td{
                                display: block;
                            }
                            tr{
                                th{
                                    border-right: none;
                                    padding-left: 20px;
                                    text-align: left;
                                    font-size: 16px;
                                    &[rowspan="3"]{
                                        &+ th{
                                            padding-top: 13px;
                                            padding-bottom: 13px;
                                        }
                                    }
                                }
                                &:nth-of-type(4), &:nth-of-type(5), {
                                    th{
                                        padding-top: 13px;
                                        padding-bottom: 13px;
                                    }
                                }
                                td{
                                    padding: 15px 20px;
                                    text-align: justify;
                                    line-height: 30px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
                .box{
                    margin-bottom: 23px;
                    .grayBox{
                        padding: 16px 21px;
                    }
                    &.box01{
                        .tableTypeBasic{
                            tr{
                                th{
                                    width: 27%; 
                                }
                            }
                        }
                    }
                }
                .clikcClose, .clikcClose02{
                    margin-top: 50px;
                    &::after{
                        margin-left: 12px;
                    }
                }
            }
            #ContBox06{
                .innerBasic{
                    padding-top: 40px;
                    padding-bottom: 58px;
                }
                .titleTypeSubTitle{
                    span{
                        font-size: 20px;
                        &::before, &::after{
                            width: 20px;
                        }
                        &::before{
                            right: -28px;
                        }
                        &::after{
                            left: -28px;
                        }
                    }
                    &+ p{
                        margin-bottom: 30px;
                    }
                }
                .listTypeFAQ{
                    margin-bottom: 20px;
                    .click{
                        padding-top: 15px;
                        padding-right: 40px;
                        padding-bottom: 15px;
                        padding-left: 55px;
                        line-height: 25px;
                        font-size: 16px;
                        &::before{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 41px;
                            height: 100%;
                            font-size: 16px;
                        }
                        &::after{
                            right: 12px;
                        }
                    }
                    .showBox{
                        padding: 12px 20px 28px 20px;
                    }
                }
            }
        }
        .modaal-wrapper{
            .modaal-inner-wrapper{
                padding-right: 0;
                padding-left: 0;
            }
            .modaal-container{
                .modaal-content-container{
                    padding: 49px 20px 52px 20px;
                }
                .modaal-close{
                    top: -24px;
                    right: 20px;
                }
                .wrapBox{
                    .imgBox{
                        margin-bottom: 37px;
                        .titleTypeBlue{
                            margin-top: 15px;
                            margin-bottom: 14px;
                        }
                    }
                    .txtBox{
                        .titleTypeBlue{
                            margin-bottom: 20px;
                        }
                        .whiteBox{
                            padding: 27px 20px 29px 20px;
                            .subBox{
                                margin-bottom: 40px;
                            }
                            .figTypescroll{
                                h6{
                                    margin-bottom: 14px;
                                }
                                p{
                                    margin-bottom: 30px;
                                }
                            }
                        }
                    }
                }

            } 
        }
    }
}
@media print, screen and (max-width: 768px) {
    #PageExaminee.pageIndex {
        #GlobalNav{
            display: none;
        }
    }
}
/*@media print, screen and (max-width: 365px) {
    #PageExaminee.pageIndex {
         #Footer{
            .footerSection01{
                .footerMenuCont{
                    .footerMenuWrap{
                        .footerMenu02{
                            .footerBtnBox{
                                .btnTypeBg{
                                    .bgErea{
                                    }
                                    .txtErea{
                                        padding-left: 28px;
                                        &::before{
                                            left: 14px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
   }
}*/
/*@media print, screen and (max-width: 340px) {
    #PageExaminee.pageIndex {
         #Footer{
            .footerSection01{
                .footerMenuCont{
                    .footerMenuWrap{
                        .footerMenu02{
                            .footerBtnBox{
                                .btnTypeBg{
                                    .bgErea{
                                    }
                                    .txtErea{
                                        padding-left: 14px;
                                        &::before{
                                            left: 7px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
   }
}*/

/*
お知らせ 共通
-------------------------------------*/
#PageTopics {
	#Main {
		background: #CBEEF2;
		background: -moz-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: -webkit-gradient(linear, left top, left bottom, from(#E4F8F1), to(#CBEEF2));
		background: -webkit-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: -o-linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		background: linear-gradient( #E4F8F1 0%, #CBEEF2 100%);
		position: relative;
		z-index: 1;
		&::before{
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-position: left top;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
		}
		&::after{
			content: "";
			display: block;
			background-repeat: no-repeat;
			background-position: right bottom;
			position: absolute;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
	}
	.contBox01{
		.contSubBox01{

		}
	}
	.contBox02{
		.innerBasic{
			max-width: 1000px;
			margin: 0 auto;
		}
		.contSubBox01{
			.btnWrap{
				
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageTopics {
		#MainImg{
			background-image: url(../img/contents/topics/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		#Main{
			&::before{
				background-image: url(../img/contents/topics/bg_01_pc.png);
				background-size: 516px auto;
				width: 516px;
				height: 489px;
			}
			&::after{
				background-image: url(../img/contents/topics/bg_02_pc.png);
				background-size: 608px auto;
				width: 608px;
				height: 633px;
			}
		}
		.contBox01{
			padding: 50px 0 40px;
			.innerBasic{
				max-width: 1000px;
				margin: 0 auto;
			}
			.contSubBox01{
			}
		}
		.contBox02{
			padding: 0 0 80px;
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics {
		#MainImg{
			background-image: url(../img/contents/topics/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
		#Main{
			&::before{
				background-image: url(../img/contents/topics/bg_01_pc.png);
				background-size: 234px auto;
				width: 234px;
				height: 300px;
			}
			&::after{
				background-image: url(../img/contents/topics/bg_02_pc.png);
				background-size: 370px auto;
				width: 370px;
				height: 284px;
			}
		}
		.contBox01{
			padding: 40px 0 30px;
			.contSubBox01{
			}
		}
		.contBox02{
			//padding: 50px 0;
			padding-top: 0;
			padding-bottom: 50px;
		}
	}
}

/*
お知らせ トップ・カテゴリ一覧
-------------------------------------*/
#PageTopics.pageIndex,
#PageTopics.pageCategory {
}
@media print, screen and (min-width: 768px) {
	#PageTopics.pageIndex,
	#PageTopics.pageCategory{
		.contBox02{
			padding-bottom: 140px;
			.contSubBox01{
				.postType03{
					margin-bottom: 30px;
				}
				.btnWrap{
					margin: 0 auto;
	    			width: 240px;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageIndex,
	#PageTopics.pageCategory{
		.contBox02{
			.contSubBox01{
				.postType02 {
					li{
						width: 100%;
						margin-right: 0;
						margin-bottom: 30px;
						.imgBox{
							height: auto;
							padding-top: 70%;
						}
						.txtBox{
							padding: 21px 18px;
							.subBox {
								.date{
									font-size: 14px;
								}
								span{
									font-size: 11px;
								}
							}
							.title{
								font-size: 16px;
							}
						}
					}
				}
				.postType03 {
					padding: 20px 20px 25px;
					width: calc(100% + 40px);
					margin-left: -20px;
					li {
						.txtBox{
							.subBox {
								.date{
									font-size: 14px;
								}
								span{
									font-size: 11px;
								}
							}
							.title{
								font-size: 16px;
							}
						}
					}
				}
				.btnWrap{
					padding: 30px 0;
					.btnTypeMore{
						width: 240px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

/*
お知らせ
-------------------------------------*/
#PageTopics.pageIndex {
	.listTypeCategory:first-of-type{
		li:first-of-type{
			a{
				color: #a8a8a8;
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageTopics.pageIndex{
	}
}
@media print, screen and (max-width: 767px) {
}


/*
カテゴリアーカイブ
-------------------------------------*/
#PageTopics.pageCategory {
	.contBox02{
		.contSubBox01{
			.postWrap{
				background-color: #fff;
				.catLabel{
				    display: inline-block;
					position: relative;
					font-weight: $medium;
				    font-size: 18px;
				    line-height: 1.5;
				    padding-left: 20px;
				    &::before{
				        content: "";
				        display: block;
				        background-color: #00B9D3;
				        width: 10px;
				        height: 25px;
				        position: absolute;
				        left: 0;
				        top: 0;
				    }
				}
				.postType03{
					
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageTopics.pageCategory {
		.contBox02{
			.contSubBox01{
				.postWrap{
					padding: 30px 30px 0;
					.catLabel{
					    margin-bottom: 7px;
					    &::before{
					    }
					}
					.postType03{
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageCategory {
		.contBox02{
			.contSubBox01{
				.postWrap{
					padding: 40px 20px 0;
					width: calc(100% + 40px);
					margin-left: -20px;
					.catLabel{
					    margin-bottom: 0;
					    &::before{
					    }
					}
					.postType03{
					}
				}
			}
		}
	}
}


/*
記事詳細
-------------------------------------*/
#PageTopics.pageEntry {
	.contBox02{
		.contSubBox01{
			background-color: #fff;
			.catLabel{
				display: inline-block;
				position: relative;
			    font-size: 18px;
			    font-weight: $medium;
			    line-height: 1.5;
			    padding-left: 20px;
			    margin-bottom: 36px;
			    &::before{
			        content: "";
			        display: block;
			        background-color: #00B9D3;
			        width: 10px;
			        height: 25px;
			        position: absolute;
			        left: 0;
			        top: 0;
			    }
			}
			.entryHead{
				.date{
					color: #A8A8A8;
					font-weight: $medium;
					font-size: 14px;
					display: block;
					margin-bottom: 12px;
				}
				.title{
					color: #1A1A1A;
					font-weight: $semibold;
					font-size: 24px;
					margin-bottom: 30px;
				}
			}
			.entryBody{

			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageTopics.pageEntry {
		.contBox02{
			padding-bottom: 120px;
			.contSubBox01{
				padding: 30px 30px;
				margin-bottom: 30px;
			}
			.wrap{
				position: relative;
				.btnTypeClose{
					width: 195px;
					position: absolute;
					top: 5px;
					left: 0;
				}
				.btnTypeBorderBasic{
					width: 265px;
					margin: 0 auto;
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageTopics.pageEntry {
		.contBox02{
			.contSubBox01{
				padding: 30px 30px;
				margin-bottom: 30px;
				.catLabel{
					margin-bottom: 18px;
				}
			}
			.wrap{
				display: flex;
    			flex-wrap: wrap;
    			.btnTypeClose{
    				width: 100%;
    				order: 2;
    				a{
	    				width: 200px;
	    				margin: 0 auto;
    				}
    			}
    			.btnTypeBorderBasic{
    				width: 100%;
    				order: 1;
    				margin-bottom: 30px;
    				border: none;
    				a{
    					width: 265px;
    					margin: 0 auto;
    					border: 1px solid #a8a8a8;
    				}
    			}
			}
		}
	}
}


/*
お問い合わせ 共通
-------------------------------------*/
#PageContact {
}
@media print, screen and (min-width: 768px) {
	#PageContact {
		#MainImg{
			background-image: url(../img/contents/contact/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		.contBox{
			max-width: 1000px;
			margin: 0 auto;
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageContact {
		#MainImg{
			background-image: url(../img/contents/contact/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
		}
	}
}


/*
お問い合わせ（入力）（内容確認） 共通
-------------------------------------*/
#PageContact.pageIndex, 
#PageContact.pageConfirm {
	.formBox{
		padding: 10px 20px;
		.tableTypeForm{
			tr{
				&:nth-of-type(1){
					th{
						&::after{
							left: 85px;
						}
					}
				}
				&:nth-of-type(2){
					th{
						&::after{
							left: 160px;
						}
					}
				}
				&:nth-of-type(3){
					th{
						&::after{
							left: 240px;
						}
					}
				}
				&:nth-of-type(4){
					th{
						&::after{
							left: 180px;
						}
					}
				}
				&:nth-of-type(5){
					th{
						&::after{
							left: 180px;
						}
					}
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageContact.pageIndex, 
	#PageContact.pageConfirm {
	}
}
@media print, screen and (max-width: 767px) {
	#PageContact.pageIndex, 
	#PageContact.pageConfirm {
		.formBox{
			padding: 12px 0;
		}
	}
}

/*
お問い合わせ
-------------------------------------*/
#PageContact.pageIndex {
	.contBox01{
		.contSubBox01{
			background-color: #FAFAFA;
			&>p{
				font-size: 18px;
				margin-bottom: 20px;
			}
			.subBox{
				margin-bottom: 20px;
				.click{
					color: #51A1F2;
					font-weight: $semibold;
					font-size: 18px;
					position: relative;
					padding: 16px 70px 16px 65px;
					line-height: 1.9;
					background-color: #fff;
					background-image: url(../img/contents/contact/icon_open.png);
					background-repeat: no-repeat;
					background-size: 20px auto;
					background-position: right 20px center;
					&.active{
						background-image: url(../img/contents/contact/icon_close.png);
					}
					&::before{
						content: "";
						display: block;
						color: #fff;
						font-weight: $semibold;
						font-size: 18px;
						background-image: url(../img/contents/contact/icon_q.png);
						background-size: 34px auto;
						background-repeat: no-repeat;
						background-position: center center;
						background-color: #51A1F2;
						text-align: center;
						border-radius: 50%;
						width: 34px;
						height: 34px;
						line-height: 34px;
						position: absolute;
						top: 16px;
						left: 15px;
					}
				}
				.showBox{
					background-color: #fff;
					margin-top: 3px;
					position: relative;
					padding: 16px 70px 16px 65px;;
					&::before{
						content: "";
						display: block;
						color: #fff;
						font-weight: $semibold;
						font-size: 18px;
						background-image: url(../img/contents/contact/icon_a.png);
						background-size: 34px auto;
						background-repeat: no-repeat;
						background-position: center center;
						background-color: #6C6C6C;
						text-align: center;
						border-radius: 50%;
						width: 34px;
						height: 34px;
						line-height: 34px;
						position: absolute;
						top: 16px;
						left: 15px;
					}
					p{
						color: #000000;
						font-weight: $regular;
						font-size: 18px;
						line-height: 1.9;
						&:last-of-type{
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.contBox02{
		p{
			margin-bottom: 22px;
		}
		.contSubBox01{
		}
	}
	.formBox{
		.tableTypeForm{
			tr{
				th{
					//contact form7用
					p{
						margin-bottom: 0;
					}
				}
				td{
					textarea{
						height: 200px;
					}
				}
			}
		}
		//contact form7用
		.wpcf7-spinner{
			display: none;
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageContact.pageIndex {
		.contBox01{
			padding: 120px 0 140px;
			.innerBasic{
				&>p{
					font-size: 18px;
				}
			}
			.contSubBox01{
				padding: 30px 30px;
				.subBox{
					.click{
						&:hover{
							opacity: $opacity;
						}
					}
				}
			}
		}
		.contBox02{
			padding-bottom: 130px;
		}
		.formBox{
			.tableTypeForm{
				margin-bottom: 10px;
				th p{
					line-height: 1.5;
				}
			}
			.btnWrap{
				width: 340px;
				margin: 0 auto;
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageContact.pageIndex {
		.contBox01{
			padding: 80px 0 70px;
			.innerBasic{
				&>p{
					font-size: 18px;
					line-height: 1.9;
				}
			}
			.contSubBox01{
				padding: 20px 20px;
				.subBox {
					&:last-of-type{
						margin-bottom: 0;
					}
					.click{
						font-size: 16px;
						padding: 16px 45px 16px 25px;
						&::before{
							top: -15px;
							left: -15px;
						}
					}
					.showBox{
						padding: 16px 30px 16px 25px;
						&::before{
							top: -15px;
							left: -15px;
						}
						p{
							font-size: 16px;
						}
					}
				}
			}
		}
		.contBox02{
			padding-bottom: 90px;
			.innerBasic{
				&>p{
					font-size: 18px;
					line-height: 1.8;
				}
			}
		}
		.formBox{
			.tableTypeForm{
				margin-bottom: 25px;
				tr{
					td{
						textarea{
		                    height: 300px;
		                }
					}
					&:last-of-type{
						td{
							margin-bottom: 0;
						}
					}
				}
			}
			.btnWrap{
				width: 300px;
				margin: 0 auto;
			}
		}
	}
}


/*
お問い合わせ（内容確認）
-------------------------------------*/
#PageContact.pageConfirm {
	.contBox01{
		p{
			font-size: 18px;
		}
		.err{
			h4{
				margin-bottom: 30px;
			}
			p{
				text-align: center;
			}
			input{
				text-decoration: underline;
			}
		}
	}
	.formBox{
		.tableTypeForm{
			tr{
				&:nth-of-type(3){
					th{
						&::after{
							left: 180px;
						}
					}
				}
				//contact form7用
				th p, td p{
					margin-bottom: 0;
					line-height: 1.5;
				}
			}
		}
		//contact form7用
		input[type="submit"]{
	        display: block;
	        border-radius: 25px;
	        padding: 12px 0;
	        width: 100%;
	        background-color: #1a1a1a;
	        text-align: center;
	        text-decoration: none;
	        font-size: 18px;
	        font-weight: $semibold;
	        color: #fff;
	    }
	    //contact form7用
	    .btnWrap01{
	    	p{
	    		margin-bottom: 0;
	        	.wpcf7-spinner{
	        		display: none;
	        	}
	    	}
	    }
	    .btnWrap02{
	    	p{
	        	text-align: center;
	    	}
	        input[type="button"]{
	            text-decoration: underline;
	            font-size: 16px;
	            color: #000;
	        }
	    }
	}
	.wrap{
		.btnWrap01{
		}
		.btnWrap02{
			text-align: center;
			a{
				color: #000000;
				font-size: 16px;
				text-decoration: underline;
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageContact.pageConfirm {
		.titleTypeBasicLower{
			margin-bottom: 45px;
		}
		.contBox01{
			padding: 120px 0 140px;
			p{
				font-size: 18px;
				margin-bottom: 35px;
			}
			.contSubBox01{
				padding: 20px 0 34px;
				.err{
					h4{
						margin-bottom: 30px;
					}
					p{
						text-align: center;
						font-size: 16px;
					}
					input{
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
			.formBox{
				.tableTypeForm{
					padding: 0 20px;
					margin-bottom: 30px;
					display: block;
				}
				tr{
					th{
						margin-bottom: 12px;
					}
					td{
						padding-left: 20px;
						margin-bottom: 38px;
					}
					&:last-of-type{
						td{
							margin-bottom: 0;
						}
					}
				}
				//contact form7用
				input[type=submit]{
					&:hover{
						opacity: $opacity;
					}
				}
				.btnWrap02 {
					input[type=button]{
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
			.wrap{
				margin-top: 55px;
				.btnWrap01{
					width: 340px;
					margin: 0 auto 24px;
				}
				.btnWrap02{
					a{
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageContact.pageConfirm {
		.contBox01{
			padding: 80px 0;
			p{
				font-size: 18px;
				line-height: 1.8;
			}
			.contSubBox01{
				padding: 20px 0 28px;
			}
			.formBox{
				.tableTypeForm{
					margin-bottom: 30px;
				}
				tr{
					th{
						margin-bottom: 12px;
					}
					td{
						padding-left: 20px;
						margin-bottom: 34px;
						line-height: 1.7;
					}
					&:last-of-type{
						td{
							margin-bottom: 0;
						}
					}
				}
			}
			.wrap{
				margin-top: 60px;
				.btnWrap01{
					width: 300px;
					margin: 0 auto 37px;
				}
				.btnWrap02{
					a{

					}
				}
			}
		}
	}
}


/*
お問い合わせ（送信完了）
-------------------------------------*/
#PageContact.pageThanks {
	.contBox01{
		p{
			font-size: 18px;
		}
		.btnWrap{
			text-align: center;
			a{
				font-size: 18px;
				color: #000000;
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageContact.pageThanks {
		.contBox01{
			padding: 120px 0 140px;
			.titleTypeBasicLower{
				margin-bottom: 43px;
			}
			p{
				margin-bottom: 55px;
			}
			.btnWrap{
				a{
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageContact.pageThanks {
		.contBox01{
			padding: 80px 0;
			.titleTypeBasicLower{
				margin-bottom: 32px;
				line-height: 1.4;
			}
			p{
				font-size: 18px;
				line-height: 1.9;
				margin-bottom: 48px;
			}
			.btnWrap{
				a{
				}
			}
		}
	}
}


/*
English
-------------------------------------*/
#PageEn.pageIndex {
	#Main{
		.contBox{
			p{
				text-align: left;
			}
		}
		.contBox03{
			.contSubBox01{
				.subBox{
					background-color: #FAFAFA;
	                .subBoxIn{
	                    h4{
	                        background-color: #51A1F2;
	                        font-weight: $semibold;
	                        color: #fff;
	                        font-size: 18px;
	                        b{
	                        	text-align: center;
	                        }
	                    }
	                    p{
	                        margin-bottom: 0;
	                        line-height: 1.5;
	                    }
	                }
				}
			}
		}
		.contBox07{
			.contSubBox{
				background-color: #F7F7F7;
				h4{
					font-weight: $bold;
					font-size: 33px;
				}
				h5{
					font-family: $noto-serif;
					font-weight: 700;
					font-size: 23px;
				}
				.subBox01{

				}
				dl{
					background-color: #fff;
					border-radius: 8px;
					dt{
						font-weight: $semibold;
						font-size: 16px;
					}
					dd{
						font-size: 16px;
						border-radius: 8px;
					}
				}
				h6{
					font-size: 16px;
				}
			}
			.contSubBox01{
				h4{
					color: #31C26E;
				}
				dl{
					background-color: #fff;
					dt{
						color: #31C26E;
					}
				}
			}
			.contSubBox02{
				h4{
					color: #51A1F2;
				}
				dl{
					dt{
						color: #51A1F2;
					}
				}
			}
		}
		#ContBox09 {
			.subBoxWrap {
				.subBox01 {
				  h4{
				  	font-weight: $medium;
				  }
				}
				.subBox02 {
					p{
						font-weight: $medium;
					}
					dl{
						dt{
							font-weight: $bold;
							color: #fff;
						}
						dd{
						}
					}
				}
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#PageEn.pageIndex {
		#MainImg{
			background-image: url(../img/contents/en/mainimage.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_pc.png);
			}
		}
		#Main{
			.contBox{
				.innerBasic{
					max-width: 1000px;
					margin: 0 auto;
				}
				p{
					font-size: 18px;
					margin-bottom: 40px;
				}
			}
			.contBox01{
				padding-top: 60px;
				padding-bottom: 100px;
			}
			.contBox02{
				padding-bottom: 40px;
				.contSubBox01{
					.flexBox{
						margin-bottom: 60px;
				        .txtBox{
				            width: calc(55% - 50px);
				        }
				        figure{
				            width: 45%;
				        }
				        &:nth-of-type(odd){
				        	flex-direction: row;
				        	.txtBox{
				        		order: 1;
				        	}
				        	figure{
				        		order: 2;
				        	}
				        }
				    }
				}
			}
			.contBox03{
				padding-bottom: 100px;
				.contSubBox01{
					&>p{
						margin-bottom: 20px;
					}
					.subBox{
	                    padding: 30px 50px;
	                    margin-bottom: 50px;
	                    .titleTypeFlgBlue{

	                    }
	                    .subBoxIn{
	                        display: flex;
	                        flex-wrap: wrap;
	                        justify-content: space-between;
	                        margin-bottom: 20px;
	                        &:last-of-type{
	                        	margin-bottom: 0;
	                        }
	                        h4{
	                            width: 120px;
	                            display: flex;
	                            align-items: center;
	                            justify-content: center;
	                        }
	                        p{
	                            width: calc(100% - 145px);
	                            display: flex;
	                            align-items: center;
	                            line-height: 1.9;
	                            padding: 7px 0;
	                        }
	                    }
	                }
				}
				.contSubBox02{
					small{
						font-size: 12px;
					}
					.flexBox{
						margin-bottom: 80px;
						justify-content: flex-start;
				        .txtBox{
				            width: calc(49% - 90px);
				            margin-right: 50px;
						    .titleTypeBlack{
						    	margin-bottom: 20px;
						    	&+p{
						    		line-height: 1.9;
						    	}
						    }
				        }
				        figure{
				            width: 51%;
				        }
				    }
					.subBox:nth-of-type(3){
						margin-bottom: 0;
					}
					.subBox:nth-of-type(4){
						.titleTypeBlack{
							margin-bottom: 0;
						}
						.txtBox{
							&>p{
								margin-bottom: 10px;
							}
						}
					}
				}
			}
			.contBox04{
				padding-bottom: 100px;
			}
			.contBox05{
				padding-bottom: 100px;
			}
			.contBox06{
				padding-bottom: 100px;
			}
			.contBox05, .contBox06, .contBox08{
				//padding-bottom: 60px;
				.contSubBox{
					.flexBox{
						.txtBox{
							width: calc(55% - 50px);
						}
						figure{
							width: 45%;
						}
					}
				}
			}
			.contBox07{
				margin-bottom: 140px;
				.contSubBox{
					padding: 50px 50px;
					h4{
						margin-bottom: 20px;
					}
					h5{
						margin-bottom: 24px;
					}
					.subBox01{
						margin-bottom: 30px;
						.txtBox{
							padding: 0 20px;
							p{
								line-height: 1.9;
								&:last-of-type{
									margin-bottom: 0;
								}
							}
						}
					}
					.flexBox{
						.txtBox{
							width: calc(57% - 50px);
						}
						figure{
							width: 43%;
						}
					}
					dl{
						padding: 30px 30px;
						margin-bottom: 38px;
						dt{
							margin-bottom: 10px;
						}
						dd{
						}
					}
					.tableTypeHeadColor{
						margin-bottom: 40px;
						tr th{
							&:nth-of-type(1){
								width: 195px;
							}
							&:nth-of-type(2){
								width: 215px;
							}
							&:nth-of-type(3){
								width: auto;
							}
						}
					}
					.btnWrap{
						width: 340px;
						margin: 0 auto;
					}
				}
			}
			.contBox08{
				padding-bottom: 95px;
				.contSubBox01{
					.subBox{
						margin-bottom: 10px;
					}
				}
				.btnWrap{
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					.btnTypeBorderBlank{
						width: 465px;
					}
				}
			}
			#ContBox09{
				padding:  40px 0 70px;
				.titleTypeBasic{
					margin-bottom: 100px;
				}
				.innerBasic {
					max-width: 1090px;
					margin: 0 auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
				}
				.map{
					width: 55.5%;
					height: 460px;
					margin-right: 70px;
					iframe{
						width: 100%;
					}
				}
				.subBoxWrap{
					width: calc(45.5% - 82px);
					.subBox01{
						margin-bottom: 30px;
						h4{
							font-size: 22px;
							margin-bottom: 6px;
						}
					}
					.subBox02{
						margin-bottom: 20px;
						p{
							margin-bottom: 7px;
						}
						dl{
							dt{
								background-color: #5E5E5E;
								font-size: 14px;
								padding: 5px 15px;
							}
							dd{
								padding: 10px 15px 15px;
							}
						}
					}
					.btnWrap{
						width: 226px;
					}
				}
			}
		}
	}
}
@media print, screen and (max-width: 767px) {
	#PageEn.pageIndex {
		#MainImg{
			background-image: url(../img/contents/en/mainimage_sp.jpg);
			&::before{
				background-image: url(../img/contents/lower/main_visu_bg_sp.png);
			}
			.mainImgTitleLower{
				//white-space: wrap;
				white-space: normal;
				word-break: break-word;
				font-size: 23px;
				width: 90%;
				max-width: 90%;
				display: inline-block;
			}
		}
		#Main{
			.contBox{
				.innerBasic{
				}
				p{
					font-size: 18px;
					//margin-bottom: 40px;
					line-height: 1.9;
				}
				.titleTypeBlue{
					margin-bottom: 5px;
				}
			}
			.contBox01{
				padding-top: 55px;
				padding-bottom: 70px;
			}
			.contBox02{
				padding-bottom: 10px;
				.contSubBox01{
					.flexBox{
						margin-bottom: 46px;
				        .txtBox{
				        }
				        figure{
				        	margin-bottom: 20px;
				        }
				    }
				}
			}
			.contBox03{
				padding-bottom: 10px;
				.contSubBox01{
					margin-bottom: 40px;
					&>p{
						margin-bottom: 20px;
					}
					.subBox{
	                    padding: 30px 20px;
	                    margin-bottom: 50px;
	                    &:last-of-type{
	                    	margin-bottom: 0;
	                    }
	                    .titleTypeFlgBlue{

	                    }
	                    .subBoxIn{
	                        margin-bottom: 20px;
	                        &:last-of-type{
	                        	margin-bottom: 0;
	                        }
	                        h4{
	                        	text-align: center;
	                        	padding: 6px 0;
	                        	margin-bottom: 5px;
	                        }
	                        p{
	                            line-height: 1.9;
	                            padding: 10px 0;
	                        }
	                    }
	                }
				}
				.contSubBox02{
					small{
						font-size: 12px;
					}
					.flexBox{
						margin-bottom: 40px;
				        .txtBox{
						    .titleTypeBlack{
						    	margin-bottom: 6px;
						    	&+p{
						    		line-height: 1.9;
						    	}
						    }
				        }
				        figure{
				        }
				    }
					.subBox:nth-of-type(3){
						margin-bottom: 45px;
					}
					.subBox:nth-of-type(4){
						.titleTypeBlack{
							margin-bottom: 10px;
						}
						.txtBox{
							&>p{
								margin-bottom: 23px;
								line-height: 1.0;
							}
						}
					}
				}
			}
			.contBox04{
				padding-bottom: 70px;
			}
			.contBox05{
				padding-bottom: 10px;
			}
			.contBox06{
				padding-bottom: 10px;
			}
			.contBox05, .contBox06, .contBox08{
				.contSubBox{
					padding-bottom: 60px;
					.flexBox{
						.txtBox{
							.titleTypeBlue{
								margin-bottom: 30px;
							}
							p{
								margin-bottom: 38px;
							}
						}
					}
				}
			}
			.contBox07{
				margin-bottom: 70px;
					.innerBasic{
						&>p{
							margin-bottom: 35px;
						}
					}
				.contSubBox{
					padding: 50px 20px;
					margin-bottom: 40px;
					&:last-of-type{
						margin-bottom: 0;
					}
					h4{
						margin-bottom: 20px;
					}
					h5{
						margin-bottom: 24px;
					}
					.subBox01{
						margin-bottom: 30px;
						.txtBox{
							p{
								line-height: 1.9;
								margin-bottom: 40px;
								&:last-of-type{
									margin-bottom: 28px;
								}
							}
						}
					}
					.flexBox{
						figure{
						}
					}
					dl{
						padding: 30px 20px;
						margin-bottom: 28px;
						dt{
							margin-bottom: 3px;
						}
						dd{
							line-height: 1.5;
						}
					}
					.titleTypeBlack{
						font-size: 16px;
					}
					.ScrollWrap{
						margin-bottom: 30px;
					}
					.tableTypeHeadColor{
						margin-bottom: 27px;
						tr th{
							&:nth-of-type(1){
								width: 195px;
							}
							&:nth-of-type(2){
								width: 215px;
							}
							&:nth-of-type(3){
								width: auto;
							}
						}
					}
					.btnWrap{
						width: 100%;
						margin: 0 auto;
					}
				}
			}
			.contBox08{
				padding-bottom: 70px;
				.contSubBox01{
					padding-bottom: 0;
					.subBox{
						margin-bottom: 43px;
					}
				}
				.btnWrap{
					width: 265px;
					margin: 0 auto;
					.btnTypeBorderBlank{
						a span::after{
							transform: translateY(-13px);
						}
					}
				}
			}
			#ContBox09{
				padding:  34px 0 50px;
				.titleTypeBasic{
					margin-bottom: 40px;
				}
				.innerBasic {
					margin: 0 auto;
				}
				.map{
					margin-bottom: 18px;
					iframe{
						width: 100%;
						height: 250px;
					}
				}
				.subBoxWrap{
					.subBox01{
						margin-bottom: 23px;
						h4{
							font-size: 22px;
							margin-bottom: 6px;
						}
					}
					.subBox02{
						margin-bottom: 10px;
						p{
							margin-bottom: 10px;
							font-size: 18px;
						}
						dl{
							dt{
								background-color: #5E5E5E;
								font-size: 14px;
								padding: 5px 15px;
							}
							dd{
								padding: 12px 15px 17px;
							}
						}
					}
					.btnWrap{
						width: 226px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}

/*
404
-------------------------------------*/
#Page404 {
	.contBox{
		text-align: center;
		p{
			text-align: center;
		}
		.linkBtn{
			a{
				color: #000;
			}
		}
	}
}
@media print, screen and (min-width: 768px) {
	#Page404 {
		.contBox{
			padding: 100px 0;
		}
	}
}
@media print, screen and (max-width: 767px) {
	#Page404 {
		.contBox{
			padding: 60px 0;
		}
	}
}
